.callout {
  @include margin(16px null 24px);

  @include grid-media($sm) {
    @include margin(32px null);
  }

  &__text {
    @include padding(16px 16px 24px);
    background: $green-blue;

    @include grid-media($sm) {
      @include padding(32px 57.5px);
    }

    * {
      color: $white;
      font-family: $GTAmericaBold;
      font-weight: $font-normal;

      @include font(14px, 20px, 0.005em);

      @include grid-media($sm) {
        @include font(30px, 46px, 0);
      }
    }
  }
}