.container {
    width: 90%;
    margin: 0 auto;
}

.country-profiles {
    width: 100%;
    background: #C05344;
    padding: 195px 50px 80px;

    h4 {
        margin: 0;
        font-family: $GTAmericaMedium;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        color: #FFFFFF;
        letter-spacing: 1px;
    }

    h1 {
        font-family: $GTAmericaMedium;
        font-weight: 700;
        font-size: 5.625rem;
        line-height: 95px;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin-bottom: 40px;
    }


    @media (max-width: 800px) {
        h1 {
            font-size: 32px;
            line-height: 32px;
            letter-spacing: 0.16px;
            margin-bottom: 32px;
        }

        h4 {
            font-size: 1rem;
        }
    }

    &__details {
        width: 80%;
        flex: initial;

        &__dropdown {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF;
            padding-right: 20px;

            input {
                background-color: inherit;
                border: none !important;
                width: 100%;
                height: 80px;
                font-family: $GTAmericaMedium;
                font-style: normal;
                font-weight: 700;
                font-size: 3rem;
                line-height: 100;
                color: #C05344;
                padding-left: 38px;
                padding-top: 16px;
                padding-bottom: 16px;
            }

            @media (max-width: 800px) {
                input {
                    font-size: 22px;
                    line-height: 100;
                    letter-spacing: 0.11px;
                    padding-left: 16px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    height: 48px;
                }
            }

            span {
                cursor: pointer;
                display: flex;

                svg {
                    @media (max-width: 800px) {
                        height: 24px;
                    }
                }
            }
        }
    }

    @media (max-width: 800px) {
        &__details {
            width: 100%;
        }
    }

    &__loading {
        position: absolute;
        width: 100%;

        span {
            color: #FFFFFF;
            display: none;
        }
    }

    input {
        &::-webkit-calendar-picker-indicator {
            display: none !important;
        }
    }

    &__list {
        width: 100%;
        display: none;

        ul {
            max-height: 400px;
            overflow: auto;
            margin: 0;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-top: 10px;
            padding-bottom: 5px;
            background-color: #FFFFFF;

            li {
                background-color: #FFFFFF;
                color: #C05344;
                padding-left: 20px;
                padding-right: 20px;
                padding: 5px 5px;

                &::before {
                    width: 1px;
                    height: 1px;
                    margin: -1px;
                    padding: 0;
                    border: 0;
                    position: absolute;
                    clip: rect(0 0 0 0);
                    overflow: hidden;
                }

                a {
                    color: #C05344;
                    width: 100%;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    padding-left: 10px;
                    font-weight: 400;
                    display: block;

                }

                a:hover {
                    background-color: #C05344;
                    color: #FFFFFF;
                }
            }
        }
    }

    &__categories {
        margin-top: 44px;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        align-content: space-between;
        gap: 31px;

        &__country {
            width: 100%;

            h4 {
                font-family: $GTAmericaMedium;
                font-style: normal;
                font-weight: 700;
                font-size: 1.375rem;
                line-height: 34px;
                letter-spacing: 0.005em;
                margin: 0;

                @media (max-width: 800px) {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.07px;
                }
            }

            hr {
                width: 100%;
                margin-block-start: 8px;
                margin-block-end: 4px;
                border-style: solid;

                @media (max-width: 800px) {
                    margin-block-start: 16px;
                    margin-block-end: 16px;
                }
            }

            ul {
                margin: 0;

                @media(max-width:800px) {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                li {
                    padding: 0;

                    &::before {
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        padding: 0;
                        border: 0;
                        position: absolute;
                        clip: rect(0 0 0 0);
                        overflow: hidden;
                    }

                    a {
                        font-family: $GTAmericaRegular;
                        font-size: 20px;
                        margin: 0;
                        color: #FFFFFF;
                        width: 100%;
                        line-height: 34px;
                        font-weight: 400;

                        @media(max-width:800px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    a:hover {
                        color: #d3d3d3;
                    }
                }
            }
        }
    }

    @media (max-width: 800px) {
        &__categories {
            flex-direction: column;
            margin-top: 48px;
            gap: 32px;
        }
    }

}

@media (max-width: 800px) {
    .country-profiles {
        padding: 48px 18px 78px;
    }
}
