.topics-slider {
  @include margin(16px null null);
  @include padding(null null 64px);
  overflow-x: hidden;

  @include grid-media($sm) {
    @include margin(0 null null);
    @include padding(64px null 192px);
  }

  &__header {
    @include margin(null null 16px);

    border-bottom: 1px solid $black;

    @include grid-media($sm) {
      @include margin(null null 40px);
    }
  }

  &__category {
    @include p3-body-copy($color: $black);
    @include margin(null null 16px);
    color: $light-brown;

    @include grid-media($sm) {
      @include margin(null null 14px);
    }
  }

  &__img {
    @include margin(null null 16px);
    max-height: 380px;

    img {
      height: 240px;
      object-fit: cover;

      @include grid-media($sm) {
        height: 380px;
      }
    }
  }

  &__title {
    @include margin(null null 16px);
    color: $green-blue;

    @include grid-media($sm) {
      @include margin(null null 20px);
    }
  }

  &__slide-title {
    @include font(24px, 24px);
    @include margin(null null 22px);
  }

  &__slide-title,
  &__description {
    color: $black;
  }

  .swiper-wrapper{
    height: fit-content;
  }

  .swiper-slide {
    flex-wrap: wrap;
    justify-content: flex-start;
    opacity: 0.4;
    text-align: left;
    transition: all $transition-single ease;

    &.swiper-slide-visible {
      opacity: 1;

      &.last-slide {
        opacity: 0.4;
      }
    }
  }

  .swiper-button-next {
    @include margin(32px null null);

    align-items: center;
    display: none;
    background: $green-blue;
    color: $white;
    height: 52px;
    justify-content: center;
    max-width: 38px;
    position: static;
    transition: all .4s ease-in-out;
    width: 100%;

    @include grid-media($sm) {
      display: flex;
    }

    &:hover {
      background: $dark-green;
    }

    &:after {
      content: none;
      display: none;
    }

    @include grid-media($sm) {
      max-width: 105px;
    }

    svg {
      height: 16px;
      width: 15px;

      @include grid-media($sm) {
        width: 100%;
      }
    }
  }

  // Modifier dark
  &--dark {
    background: $zeus;

    .topics-slider__title,
    .topics-slider__slide-title,
    .topics-slider__description,
    .topics-slider__category {
      color: $white;
    }

    .topics-slider__header {
      border-bottom: 1px solid $white;
    }
  }
}