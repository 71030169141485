.author-block {
  @include margin(null null 32px);

  @include grid-media($sm) {
    @include margin(null null 95px);
  }

  &__row {
    @include margin(null null 15px);

    @include grid-media($sm) {
      @include margin(null null 48px);
    }
  }

  &__title {
    @include margin(null null 10px);
    @include padding(null null 16px);
    border-bottom: 1px solid rgba($black, 0.25);

    @include grid-media($sm) {
      @include margin(null null 26px);
      @include padding(null null 48px);
    }
  }

  &__item {
    @include margin(null null 11px);
    @include padding(null null 16px);
    border-bottom: 1px solid rgba($black, 0.25);
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      @include margin(null null 0);
    }

    @include grid-media($sm) {
      @include margin(null null 26px);
      @include padding(null null 32px);
    }
  }

  &__image {
    @include margin(null null 8px);
    border-radius: 50%;
    height: 184px;
    object-fit: cover;
    width: 184px;

    @include grid-media($sm) {
      @include margin(null 70px 0 0);
    }
  }

  &__details {
    @include grid-media($sm) {
      width: calc(100% - 254px);
    }

    &-name {
      @include p2-body-copy($color: $black);
      font-family: $GTAmericaRegular;
      font-weight: $font-normal;
    }

    &-designation {
      @include font(20px, 34px);
      @include margin(null null 8px);
      color: $gray-light-1;
      font-family: $GTAmericaBold;

      @include grid-media($sm) {
        @include margin(null null 50px);
      }
    }

    &-description {
      @include p4-body-copy($color: $black);
    }
  }
}