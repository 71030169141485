/**
  COLORS
  color palette
**/

$colors: (
    neutrals: (
        white: rgba(255,255,255,1),
        gray: rgba(74, 74, 74, 1),
        lightgray: rgba(229, 229, 229, 1),
        black: rgba(47, 47, 47, 1)
    ),
    greens: (
        light: rgba(214, 237, 234, 1),
        dark: rgba(0, 167, 146, 1)
    ),
    red: rgba(238, 81, 71, 1),
    blue: rgba(89, 130, 195, 1),
    purple: rgba(167, 183, 221, 1),
    orange: rgba(247, 147, 17, 1),
    lighterorange: rgba(255, 213, 128, 1),
);

$black: #000000;
$white: #ffffff;
$thunder: #2f2f2f;
$dark-gray: #333333;
$gray-light: #6f6f6f;
$gray-light-1: #7c7c7c;
$gray-lighter: #e5e5e5;
$light-brown: #9d9d9d;
$zeus: #221e1e;
$green-blue: #17aea5;
$dark-green: #295E5B;
$light-green: #e3f1f0;
$light-green-1: #d3f9f7;
$blue-chill: #129089;
$green-dark: #4d7a8f;
$off-white: #f7f7f7;
$grey: #909090;
$maroon: #c05344;
$dark-maroon: #9a3021;
$light-blue: #c8f4f2;
$purple: #614e68;
$beige: #f2eee7;
$beige-light: #f8f7f3;
$light-pink: #fbf0ee;
$darker-green:rgba(0, 167, 146, 1);
$darkblack: rgba(47, 47, 47, 1);
$darkergray: rgba(74, 74, 74, 1);
