.search-result {
  @include margin(32px null null);

  @include grid-media($md) {
    @include margin(64px null null);
  }

  .facetwp-facet-s {
    display: none;
  }

  &__top {
    @include margin(null null 48px);

    @include grid-media($md) {
      @include margin(null null 56px);
    }

    .search-result__number {
      @include font(16px, 24px);
      color: $black;

      @include grid-media($md) {
        @include font(20px, 34px);
      }
    }
  }

  &__title {
    @include margin(null null 13px);

    @include grid-media($md) {
      @include margin(null null 25px);

    }
  }

  &__sort {
    @include margin(0 null 18px);

    @include grid-media($md) {
      @include margin(0 null 21px);
    }
  }

  .facetwp-type-checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .facetwp-checkbox {
      @include font(20px, 34px);
      font-family: $GTAmericaRegular;
    }
  }

  .search__result-title,
  .search__result-subtitle {
    @include margin(0 null 8px);
  }

  .search__result-title {
    @include font(22px, 34px, 0.01em);
    color: $dark-gray;
    display: block;
    font-family: $GTAmericaBold;
  }

  .search__result-subtitle {
    @include font(16px, 18px);
    color: $light-brown;
    font-family: $GTAmericaRegular;
  }

  .search__result-columns {
    @include padding(null null 32px);

    p {
      @include font(14px, 22px);
      color: $black;

      @include grid-media($md) {
        @include font(16px, 22px);
      }
    }
  }

  .facetwp-template {
    hr {
      @include margin(0 null 32px);

      &:last-child {
        @include margin(null null 24px);
      }
    }
  }

  .facetwp-facet-search_pager {
    @include margin(24px null 64px);
    text-align: center;

    @include grid-media($md) {
      @include margin(null null 128px);
    }

    .facetwp-pager {
      @include margin(null auto);
      width: 100%;
      display: inline-block;

      a {
        @include font(20px, 24px);
        @include margin(null 15px null null);
        color: $thunder;
        font-family: $GTAmericaRegular;

        &:hover,
        &:focus {
          color: $green-blue;
          opacity: 1 !important;
        }

        &.next,
        &.prev {
          @include padding(null 0);
          color: transparent;
          overflow: hidden;
          vertical-align: bottom;
          white-space: nowrap;
          width: 26px;

          &:before {
            color: $thunder;
            font-size: 1.2em;
          }
        }

        &.next {
          &:before {
            content: "\2192";
          }
        }

        &.prev {
          &:before {
            content: "\2190";
          }
        }

        &.active {
          color: $green-blue;
          font-family: $GTAmericaBold;
        }
      }
    }
  }
}