.view-source {
    .component__content {
        padding: 0;
        margin: 0;
        max-width: 100%;
    }

    &__inner {
        background: #F8F7F3;
        padding: 127px 262px 86px;
        text-align: center;

        @media (max-width: 800px) {
            padding: 72px 14px 65px 18px;
        }
    }

    &__country {
        color: #C05344;
        font-family: $GTAmericaMedium;
        font-size: 72px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: 0.36px;

        @media (max-width: 800px) {
            font-size: 32px;
            line-height: normal;
            letter-spacing: 0.16px;
            text-align: left;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 45px;
        margin-top: 72px;

        @media (max-width: 800px) {
            display: block;
            margin-top: 32px;
        }

        &.desktop{
            display: flex;

            @media (max-width: 800px) {
                display: none;
            }
        }
        
        &.mobile{
            display: none;

            @media (max-width: 800px) {
                display: block;
                margin-top: 32px;
            }
        }
    }

    &__button {
        border: 2px solid #C05344;
        width: 218px;
        height: 79px;
        text-align: center;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: #C05344;
        font-family: $GTAmericaMedium;
        font-size: 20px;
        font-weight: 700;
        line-height: 34px;
        margin: 0 !important;

        @media (max-width: 800px) {
            margin-bottom: 16px !important;
            width: 100%;
            height: 48px;
            font-size: 20px;
            line-height: 24px;
        }
    }
}
