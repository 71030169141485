.subscribe {
  @include padding(28px 23px 40.28px 21px);
  background-color: $green-blue;

  @include grid-media($md) {
    @include padding(43.99px 79.75px 60.39px 49.86px);
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  &__title {
    @include margin(null null 24px);
    color: $white;

    @include grid-media($md) {
      @include margin(null null 34.81px);
    }
  }

  &__form {
    position: relative;
    width: 100%;
    border: none;

    .mc4wp-form,
    .mc4wp-form-fields,
    input {
      height: 100%;
    }

    .mc4wp-form{
      display: block;
    }

    .mc4wp-form-fields{
      display: block;

      @include grid-media($md){
        display: flex;
      }
    }
  }

  &,
  &__form {
    input[type="text"],
    input[type="email"],
    input[type="submit"] {
      @include padding(0);
      border: 1px solid $white;
      font-family: $GTAmericaRegular;
    }

    input[type="text"],
    input[type="email"] {
      @include padding(17px 12px);
      @include font(16px, 24px);
      margin-bottom: 16px;
      background-color: transparent;
      color: $white;
      width: 100%;
      height: 53px;
      font-weight: 400;

      @include grid-media($md) {
        @include padding(11.04px 11.61px 12.2px);
        @include font(24.65px, 48.34px);
        font-weight: 300;
        margin-right: 15.35px;
        width: 20%;
        height: 65.05px;
      }

      @include grid-media($ml){
        width: 277.49px;
      }
    }

    input[type="submit"] {
      background: url(assets/img/subscribe-arrow.svg) center center no-repeat;
      background-color: $white;
      cursor: pointer;
      width: 113.59px;
      height: 55.72px;
      float: right;
      color: transparent;

      @include grid-media($md){
        width: 125.55px;
        height: 61.59px;
        float: none;
      }
    }
  }
}
