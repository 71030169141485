//*------------------------------------*\
//    $TWO COLUMN GRID COMPONENT
//*------------------------------------*/

.two-colgrid {
    margin-bottom: px-to-rem(20px);
    
    @media screen and (min-width: '#{$small-breakpoint}px') {
        margin-bottom: px-to-rem(30px);
    }

    .component__content {
        display: grid;
        row-gap: px-to-rem(30px);

        @media screen and (min-width: '#{$small-breakpoint}px') {
            grid-template-columns: repeat(2, 1fr);
            column-gap: px-to-rem(30px);
            row-gap: px-to-rem(30px);
        }
    }

    &__img {
        aspect-ratio: 16/9;
        margin-bottom: px-to-rem(10px);
        width: 100%;
    }

    &__title {
        margin-bottom: px-to-rem(15px);
    }

    &__text {
        margin-bottom: 0 !important;
    }
}