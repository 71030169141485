.current-policies {
    .component__content {
        margin: 0;
        max-width: 100%;
        padding: 0 50px;

        @media (max-width: 800px) {
            padding: 0 18px;
        }
    }

    &__container {
        border-top: 1px solid #000;
        padding-top: 38px;
        padding-bottom: 87px;

        @media (max-width: 800px) {
            padding-top: 38.5px;
            padding-bottom: 70px;
        }
    }

    h4 {
        color: #17AEA5;
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        text-align: left;
        margin-bottom: 34px;

        @media (max-width: 800px) {
            font-size: 20px;
            line-height: 26px;
            letter-spacing: 0.1px;
            margin-bottom: 16px;
        }
    }

    h3 {
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        text-align: left;
        margin-bottom: 21px;

        @media (max-width: 800px) {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 47px;
        }
    }

    &__blocks {
        display: flex;
        flex-direction: row;
        gap: 30px;
        justify-content: space-between;

        &__block {
            display: flex;
            flex-direction: column;
            row-gap: 19px;

            &__square {
                height: 106px;
                width: 106px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.875rem;
                font-weight: 700;
                font-family: $GTAmericaMedium;

                p {
                    font-family: $GTAmericaMedium;
                    margin: 0;
                    padding: 0;
                    color: #fff;
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 46px;
                }
            }

            .green {
                background-color: #17aea5;
            }

            .red {
                background-color: #C05344;
                ;
            }
            .gray {
                background-color: #A6A6A6;
                ;
            }

            &__text {
                width: 106px;
                font-family: $GTAmericaMedium;
                font-size: 16px;
                font-weight: 700;
                line-height: 18px;
                margin: 0 !important;

                @media (max-width: 500px) {
                    font-size: 18px;
                }
            }

            @media (max-width: 500px) {
                width: 30%;
            }

            @media (max-width: 375px) {
                width: 38%;
            }
        }

        @media (max-width: 1300px) {
            flex-wrap: wrap;
            justify-content: start;
            gap: 34px;
        }

        @media (max-width: 500px) {
            justify-content: space-between;
        }

    }
}
