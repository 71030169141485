.home-slider {
  @include padding(42px null 115px);
  background-color: $maroon;

  @include grid-media($md) {
    @include padding(95px null 128px);
  }

  .container {
    position: relative;
  }

  &__desc {
    @include font(16px, 24px);
    @include margin(null null 30px);
    font-family: $GTAmericaRegular;
    font-weight: $font-normal;

    @include grid-media($sm) {
      @include margin(null null 80px);
      @include font(30px, 46px);
    }

    @include grid-media($md) {
      @include margin(null null auto);
      max-width: 500px;
    }

    u {
      text-decoration: underline 3px solid $thunder;
      text-underline-offset: 3px;
      font-family: $GTAmericaBold;

      @include grid-media($sm) {
        text-decoration: underline 6px solid $thunder;
        text-underline-offset: 10px;
      }
    }
  }

  &__stats {
    @include margin(null null 24px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @include grid-media($md) {
      @include margin(null null 38px);
      flex-direction: row;
      justify-content: unset;
    }

    dl {
      @include margin(0);
      text-align: center;

      &:first-child {
        @include margin(null null 14px);

        @include grid-media($md) {
          @include margin(null null 0);
        }
      }
    }

    dt {
      @include margin(null null 5px);

      @include grid-media($md) {
        @include margin(null null 0);
      }
    }

    dd {
      @include margin(0);
      @include font(16px, 24px);
      color: $thunder;
      font-family: $GTAmericaRegular;

      @include grid-media($sm) {
        @include font(30px, 46px);
      }
    }

    &-arrow {
      color: $maroon;
      display: none;
      max-height: 110px;

      @include grid-media($md) {
        @include margin(null 24px);
        display: flex;
      }

      &,
      svg {
        max-width: 48px;
      }
    }
  }

  &__img {
    @include margin(null auto);
    mix-blend-mode: multiply;

    @include grid-media($md) {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 50%;
    }

    @include grid-media($lg) {
      max-width: 100%;
    }
  }

  &__title {
    @include margin(null null 18px);
    color: $dark-maroon;
    font-weight: $font-normal;

    @include grid-media($md) {
      @include margin(null null 32px);
    }
  }

  &__link {
    @include margin(null auto);
    @extend h6;
    color: $thunder;
    display: grid;
    text-align: center;

    @include grid-media($md) {
      @include margin(auto 0 0);
      text-align: unset;
    }
  }

  &__slider {
    overflow: hidden;

    &-title,
    .home-slider__stats {
      @include font(32px, 32px);
      color: $maroon;
      font-family: $GTAmericaBold;

      @include grid-media($sm) {
        @include font(90px, 95px);
      }
    }

    &-title {
      @include margin(null null 24px);
      text-align: center;

      @include grid-media($md) {
        @include margin(null null 0);
        text-align: unset;
      }
    }

    .swiper-wrapper{
      height: fit-content;
    }

    .swiper-slide {
      @include padding(28px 17px null 28px);
      background-color: $beige;
      min-height: 496px;

      @include grid-media($md) {
        @include padding(92px 113px 40px 113px);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 580px;
      }

      &--dark {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        background-color: $thunder;
        text-align: center;

        *:not(.home-slider__slider-title) {
          color: $white;
        }

        .home-slider__desc {
          @include margin(auto auto null);
          max-width: 600px;

          @include grid-media($md) {
            @include margin(86px null 23px);
          }
        }

        .home-slider__link {
          @include margin(auto null 80px);

          @include grid-media($md) {
            @include margin(auto null 0);
          }
        }
      }
    }

    .swiper-pagination {
      bottom: -85px !important;

      @include grid-media($md) {
        @include padding(null null null 50px);
        bottom: -50px !important;
        text-align: left;
      }

      span {
        @include p3-body-copy($color: $white);
        background: none;
        color: $white;
        height: unset;
        line-height: 1;
        margin: 0 8px !important;
        opacity: 1;
        transition: all 0.4s ease;
        width: unset;

        &:hover {
          color: $thunder;
        }
      }

      .swiper-pagination-bullet-active {
        color: $thunder;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-position: under;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: grid;
      height: 80px;
      background-color: $green-blue;
      bottom: 12px;
      color: $white;
      top: auto;
      transform: translateY(50px);
      transition: all 0.4s ease;
      width: 80px;

      @include grid-media($md) {
        bottom: 0;
        height: 106px;
        width: 106px;
      }

      &:hover {
        background-color: $dark-green;
      }

      &:after {
        content: none;
        display: none;
      }

      svg {
        width: 40px;
      }

      &.swiper-button-disabled {
        background-color: $green-blue;
        opacity: 1;
      }
    }

    .swiper-button-next {
      right: 49%;
      transform: translateX(100%) translateY(50px);

      @include grid-media($md) {
        right: 120px;
        transform: translateX(0) translateY(50px);
      }
    }

    .swiper-button-prev {
      left: 49%;
      transform: translateX(-100%) translateY(50px);
      right: auto;

      @include grid-media($md) {
        left: auto;
        right: 240px;
        transform: translateX(0) translateY(50px);
      }

      svg {
        transform: rotate(-180deg);
      }
    }
  }
}