.video-embed {
  &__video {
    @include padding(56.25% null null);
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}