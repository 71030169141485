.csoon {
  @include margin(null null 44px);
  display: block;

  @include grid-media($md) {
    @include margin(-34vh null 66px);
  }

  * {
    color: $white;
  }

  &__wrapper {
    @include padding(33px null 36px);
    background-color: $thunder;

    @include grid-media($md) {
      @include padding(90.92px 98px 76.25px);
      column-gap: 8%;
      display: grid;
      grid-template-columns: 478px 1fr;
    }
  }

  .container {
    @include padding(33px null 36px);
    background-color: $thunder;

    @include grid-media($md) {
      @include padding(0 null);
      background: none;
    }
  }

  .subscribe__form {
    @include margin(null null 12px);

    .cta-newsletter__button {
      @include grid-media($md) {
        right: 10px;
      }
    }

    .mc4wp-form,
    .mc4wp-form-fields,
    input {
      height: 100%;
    }
  }

  &__title {
    @include font(26px, 46px);

    @include grid-media($md) {
      @include font(46.92px, 48.27px);
      letter-spacing: 0.005em;
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    @include font(16px, 24px);
    letter-spacing: 0.005em;

    @include grid-media($md) {
      margin-bottom: 29.33px;
      @include font(29.33px, 48.27px);
    }
  }

  &__left {
    @include margin(null null 38px);
    display: flex;
    text-align: left;
    flex-direction: column;
    flex-wrap: wrap;

    @include grid-media($md) {
      @include margin(null null 0);
    }

    .subscribe__form {
      @include margin(null 0 null);

      .mc4wp-form-fields {
        display: block;
      }

      input[type="text"],
      input[type="email"] {
        width: 100%;
        margin-bottom: 16px;
        @include font(16px, 24px);

        @include grid-media($md) {
          @include font(32.24px, 49.43px);
          margin-bottom: 21.27px;
        }
      }

      input[type="submit"] {
        float: right;
        background: url(assets/img/coming-soon.svg) center center no-repeat;
        background-color: $green-blue;
        border: 1px solid $green-blue;
        width: 113.59px;
        height: 55.72px;
      }
    }
  }

  &__section-title {
    @include font(26px, 46px);
    margin-bottom: 26.24px;

    @include grid-media($md) {
      @include font(46.92px, 48.27px);
      letter-spacing: 0.005em;
    }
  }

  &__list {
    >li {
      @include padding(null null 14px);

      @include grid-media($md) {
        @include padding(null null 22px);
      }

      &:not(:last-child) {
        @include margin(null null 14px);
        border-bottom: 1px solid $white;

        @include grid-media($md) {
          @include margin(null null 22px);
        }
      }
    }

    &-title {
      @include p3-body-copy($color: $white);
      @include margin(0 null 5px);
      @include font(14px, 20px);
      letter-spacing: 0.005em;

      @include grid-media($md) {
        @include margin(0 null 8px);
        @include font(23.64px, 36.54px);
      }
    }

    &-desc {
      @include font(16px, 24px);

      @include grid-media($md) {
        @include font(21.49px, 36.54px);
      }
    }
  }
}
