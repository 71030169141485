/**
CSS RESET
**/


html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, p, blockquote, figure, ol, ul {
  margin: 0;
  padding: 0;
}

main, li {
  display: block;
}

ol, ul {
  list-style: none;
}

h1, h2, h3, h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a, [role="button"], .ajax, .cta {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

:focus {
  outline: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

blockquote, q {
  quotes: none;
}
