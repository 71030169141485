.addtoany_list {
  a {
    padding: 0 !important;

    span {
      transition: all 0.4s ease;
    }

    &:not(:last-child) {
      span {
        @include margin(null 20px null null);
      }
    }

    &:hover {
      img,
      span {
        opacity: unset !important;
      }

      span {
        background-color: $maroon !important;
      }
    }
  }
}

.a2a_svg {
  align-items: center !important;
  border-radius: 50% !important;
  display: flex !important;

  @include grid-media($sm) {
    height: 48px !important;
    width: 48px !important;
  }

  svg {
    width: 80% !important;
  }
}

.addtoany_share {
  display: none !important;
}
