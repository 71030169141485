.triple-image {
  .container {
    @include grid-media($sm) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
    }
  }

  &__caption {
    @include margin(8px null null);
    @include caption;
    color: $gray-light  !important;

    @include grid-media($sm) {
      @include margin(16px null null);
    }
  }
}