.header {
  position: fixed;
  top: 0;
  transition: all $transition-single ease;
  width: 100%;
  z-index: 20;

  @include grid-media($md) {
    height: 78px;
  }

  &.js-shrink {
    transition: all 0.4s $cubic-transition-2;
    transform: translateY(0);
  }

  &.js-snap-up {
    transition: all 0.4s $cubic-transition-2;
    transform: translateY(-180px);
  }

  &__wrapper {
    @include padding(18.5px);

    background-color: $thunder;
    display: flex;

    @include grid-media($md) {
      @include padding(0);

      align-items: center;
      height: 100%;
    }

    &.no-hover {
      @include grid-media($md) {
        &:hover {
          background-color: $thunder;
        }
      }
    }
  }

  &__language,
  &__wrapper {
    pointer-events: all;
    transition: all $transition-single ease;

    @include grid-media($md) {
      &:hover {
        background-color: $zeus;
      }
    }
  }

  &__nav {
    @include margin(null null null auto);

    @include grid-media($md) {
      display: flex;
      height: 100%;
    }

    &.disappear {
      @include grid-media($md) {
        display: none;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;

      @include grid-media($md) {
        display: unset;
      }
    }
  }

  &__search-nav {
    height: 100%;
    position: absolute;
    opacity: 0;
    overflow: hidden;
    width: 0;
    right: 0;

    &.visible {
      @include grid-media($md) {
        opacity: 1;
        overflow: unset;
        width: 100%;
      }
    }

    &.animate {
      transition: all 0.5s $cubic-transition-2;
    }
  }

  &__search-bar {
    @include padding(25px 35px);

    background-color: $green-blue;
    display: flex;
    flex-direction: row;
    height: 78px;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 325px);

    &-input {
      @include font(26px, 185%, -0.022em);

      background-color: transparent;
      border: 0;
      caret-color: $white;
      color: $white;
      width: 100%;

      &::placeholder {
        @include font(26px, 185%, -0.022em);

        color: $white;
        opacity: 0.25;
      }
    }

    &-submit {
      display: none;
    }

    &-cancel {
      background: url("assets/img/close.svg") center no-repeat;
      background-size: contain;
      cursor: pointer;
      height: 100%;
      padding: 0 10px;
      width: 15px;
    }
  }

  &__language {
    background-color: $thunder;
    border-left: 1px solid $white;
    cursor: pointer;
    height: 100%;
    width: 78px;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      li {
        @include font(24px, 200%, -0.022em);

        color: $white;
        font-family: $GTAmericaMedium;
        text-transform: uppercase;
      }
    }
  }

  &__search-main {
    height: 100%;

    .search-button {
      @include padding(10px);

      background: url("assets/img/search-icon.svg") center center $green-blue no-repeat;
      background-size: 31px;
      border: none;
      cursor: pointer;
      height: 100%;
      pointer-events: unset;
      width: 78px;
      pointer-events: all;
      transition: all $transition-single ease;

      &:hover {
        background-color: $blue-chill;
      }
    }
  }

  &__hamburger {
    &-icon {
      @include margin(null null null auto);

      @include grid-media($md) {
        @include margin(null 30px null null);

        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    &-title {
      display: none;

      @include grid-media($md) {
        @include font(26px, 185%, -0.022em);
        @include margin(null 20px null null);

        color: $white;
        font-family: $GTAmericaMedium;
        display: block;
        font-weight: $font-normal;
      }
    }
  }

  &__logo {
    &-link {
      display: block;
      line-height: 0;

      @include grid-media($md) {
        @include padding(18px null 18px 55px);
        @include margin(null 53px null null);
      }
    }

    &--xs {
      @include grid-media($md) {
        max-width: 217px;
      }
    }
  }

  &__search {
    display: flex;
    height: 44px;

    @include grid-media($md) {
      display: none;
    }

    form {
      @include padding(7px 18px);

      align-items: center;
      background-color: $green-blue;
      display: flex;
      width: 80%;

      .search-field {
        @include font(14.6px, 185%, -0.022em);

        background-color: transparent;
        border: 0;
        caret-color: $white;
        color: $white;
        width: 100%;

        &::placeholder {
          @include font(14.6px, 185%, -0.022em);

          color: $white;
          opacity: 0.25;
        }
      }

      .submit-btn {
        @include padding(10px);

        background: url("assets/img/search-icon.svg") center center no-repeat;
        background-size: 19px 19px;
        border: none;
        cursor: pointer;
        pointer-events: unset;
      }
    }

    &-language-mobile {
      background-color: $thunder;
      width: 20%;

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        li {
          @include font(16px, 300%, -0.022em);

          color: $white;
          text-transform: uppercase;
        }
      }
    }
  }

  &__main {
    background-color: $zeus;
    display: none;
    height: 100vh;
  }

  &__scroll-wrapper {
    @include grid-media($lg) {
      max-height: calc(100vh - 78px);
    }

    @include grid-media($xl) {
      max-height: calc(100vh - 78px);
    }
  }

  &__nav-wrapper {
    @include padding(18px);
    overflow-y: auto;

    @include grid-media($md) {
      @include padding(60px 85px 102px);

      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      height: calc(100vh - 162px);
      overflow-y: hidden;
      position: relative;
      width: 100%;
    }
  }

  &__nav-button {
    @include grid-media($md) {
      background: url("assets/img/close.svg") center center no-repeat;
      background-size: 31px;
      border: none;
      cursor: pointer;
      height: 24px;
      pointer-events: unset;
      position: absolute;
      right: 125px;
      top: 62px;
      width: 24px;
      transition: all $transition-single ease-in-out;
      transform: scale(1);
      z-index: 20;

      &:hover {
        background: url("assets/img/close-green.svg") center center no-repeat;
        transform: scale(1.2);
      }
    }
  }

  &__center,
  &__left,
  &__right {
    &-title {
      @include font(19px, 203%, -0.022em);

      color: $white;
      font-family: $GTAmericaBlack;

      @include grid-media($lg) {
        @include font(28px, 171%, -0.022em);
        @include margin(null null 15px);
      }
    }
  }

  &__center,
  &__left,
  &__right {
    &-link {
      transition: all $transition-single ease;

      &:hover {
        color: $blue-chill;
      }
    }
  }

  &__center {
    @include padding(20px null 36px);

    border-bottom: 1px solid $white;
    order: 2;

    @include grid-media($md) {
      @include padding(0 50px null);

      border-bottom: none;
      border-left: 1px solid $white;
      border-right: 1px solid $white;
      order: 2;
    }

    @include grid-media($xl) {
      @include padding(null 85px null 50px);
    }

    &-link {
      @include font(24px, 142%, -0.022em);

      color: $white;
      font-family: $GTAmericaRegular;
      font-weight: $font-normal;

      @include grid-media($lg) {
        @include font(40px, 120%);
      }
    }
  }

  &__left {
    order: 3;

    @include grid-media($md) {
      @include padding(null null 80px);
      order: 3;
      max-height: calc(100vh - 80px);
    }

    @include grid-media($lg) {
      @include padding(null null null 50px);
    }

    @include grid-media($xl) {
      @include padding(null null null 0);
    }

    &-title {
      @include margin(null null 10px);

      @include grid-media($lg) {
        @include margin(null null 15px);
      }
    }

    &-wrapper {
      @include padding(20px null 36px);

      border-bottom: 1px solid $white;

      @include grid-media($md) {
        @include padding(0 null null 54px);

        border-bottom: none;
      }

      @include grid-media($lg) {
        @include padding(null null 67px);
      }
    }

    &-items {
      columns: 2;
      column-gap: 35px;

      @include grid-media($lg) {
        column-gap: 125px;
      }
    }

    &-link {
      @include font(15px, 178%);

      color: $white;
      font-family: $GTAmericaRegular;
      font-weight: $font-normal;

      @include grid-media($lg) {
        @include font(22px, 152%);
      }
    }
  }

  &__right {
    @include padding(null null 36px);
    border-bottom: 1px solid $white;
    order: 1;

    @include grid-media($md) {
      @include padding(0 null 0);
      border-bottom: none;
    }

    @include grid-media($lg) {
      @include padding(null 50px null null);

      align-items: flex-start;
      display: flex;
      height: 100%;
    }

    @include grid-media($xl) {
      @include padding(null 0 null null);
    }

    &-link {
      @include font(38px, 109%, -0.022em);

      color: $white;
      font-family: $GTAmericaBold;

      @include grid-media($lg) {
        @include font(60px, 109%);
      }
    }
  }

  &__announcement-bar {
    background: $maroon;
    display: block;
    color: $white;

    &__wrapper{
      @include padding(18px);

      @include grid-media($md) {
        @include padding(9px 57px 6px);
      }
    }

    &__text {
      @include font(24px, 200%, -0.528px);

      color: $white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $GTAmericaRegular;
      font-weight: $font-normal;

      &.mobile {
        display: block;

        @include grid-media($sm) {
          display: none;
        }
      }

      &.desktop {
        display: none;

        @include grid-media($sm) {
          display: block;
        }
      }
    }

    &:hover {
      color: $white !important;
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-thickness: 1.5px;
    }
  }
}