.state {
  @include padding(60px null);
  background-color: $thunder;

  @include grid-media($md) {
    @include padding(116px null);
  }

  * {
    color: $white;
  }

  .container {
    max-width: 1166px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    @include margin(null null 18px);
    @include p2-body-copy($color: $white);
    font-family: $GTAmericaRegular;
    font-weight: $font-normal;
    width: 100%;

    @include grid-media($md) {
      @include margin(null null 0);
      max-width: 428px;
    }
  }

  &__description {
    @include font(16px, 24px);

    @include grid-media($sm) {
      @include font(30px, 46px);
    }

    @include grid-media($md) {
      @include margin(null null null 120px);
      width: calc(100% - 548px);
    }
  }
}