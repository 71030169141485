.share {
  @include margin(32px auto);

  display: inline-flex;

  li {
    &:not(:last-child) {
      @include margin(null 8px null null);
    }

    &,
    svg {
      height: 24px;
      width: 24px;

      @include grid-media($md) {
        height: 48px;
        width: 48px;
      }
    }

    @include grid-media($md) {
      &:not(:last-child) {
        @include margin(null 0 16px null);
      }
    }

    > a {
      color: $thunder;
      display: inline-block;
      line-height: 1;
      transition: all .4s ease;

      &:hover {
        color: $maroon;
      }
    }

    svg {
      width: 100%;
    }
  }
}