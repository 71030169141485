.single-post {
  &.logged-in {
    padding-top: 13px;

    @include grid-media($md) {
      padding-top: 9px;
    }

    @include grid-media($md) {
      padding-top: 27px;
    }
  }

  .single-image {
    @include margin(null null 55px);
  }

  .publish-details {
    @include margin(null auto 24px);
    @include padding(null null 0);
    border: none;

    @include grid-media($sm) {
      @include padding(null 50px);
    }

    .container {
      @include padding(null 0 32px);
      border-bottom: 1px solid $black;
      max-width: 793px;

      @include grid-media($sm) {
        @include padding(null null 48px);
      }
    }
  }

  .addtoany_shortcode {
    @include margin(0 auto);
    @include padding(null 18px);
    display: flex;
    justify-content: center;
    max-width: 893px;

    @include grid-media($sm) {
      @include padding(null 50px);
    }

    .a2a_kit a {
      &:not(:last-child) {
        span {
          @include margin(null 16px 0 null);
        }
      }
    }
  }
}