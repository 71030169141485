.harms-impact {
    font-family: $GTAmericaMedium;

    &__inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px 102px;
        align-items: start;
        margin: 52px 0 60px;

        &.tobacco-impact {
            align-items: center;

            @media (max-width: 800px) {
                margin-bottom: 50px;
            }
        }

        @media (max-width: 1300px) {
            grid-gap: 24px 30px;
        }

        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 48px 0;
            align-items: center;
            margin: 16px 0 67px;
        }
    }

    &__container {
        border-top: 1px solid #2f2f2f;
    }

    .component__content {
        margin: 0;
        padding: 0 49px;
        max-width: 100%;

        @media (max-width: 800px) {
            padding: 0 18px;
        }
    }

    .issue__heading {
        margin-top: 49px;

        @media (max-width: 800px) {
            letter-spacing: 0.16px;
            margin-bottom: 16px;
        }
    }

    &__subtitle {
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        max-width: 424px;

        &.tobacco-subtitle{
            @media (min-width: 800px) {
              font-size: 32px;
            }
        }

        @media (max-width: 800px) {
            font-size: 20px;
            line-height: normal;
            letter-spacing: 0.1px;
            max-width: 100%;
            margin-bottom: 48px !important;
        }

    }

    &__heading {
        color: #C05344;
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 0;

        @media (max-width: 800px) {
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.1px;
            margin-bottom: 16px;
        }
    }

    &__description {
        color: #2F2F2F;
        font-family: $GTAmericaRegular;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.65;
        margin-bottom: 0 !important;

        @media (max-width: 800px) {
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
        }
    }

    &__image {
        width: 572px;
        height: 210px;
        margin-bottom: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @media (max-width: 1300px){
            width: 100%;
        }

        @media (max-width: 800px) {
            width: 100%;
            height: 216px;
            margin-bottom: 16px;
        }
    }
}

.harms__learn-more {
    color: #2F2F2F;
    text-align: center;
    font-family: $GTAmericaRegular;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 2.625rem;

    a {
        font-family: $GTAmericaMedium;
        color: #C05344;
        font-weight: 700;
        font-size: 32px;

        @media (max-width: 800px) {
            font-size: 24px;
        }
    }

    @media (max-width: 800px) {
        font-size: 24px;
        line-height: normal;
        margin-bottom: 34px !important;
    }

    &.tobacco {
        margin-bottom: 2.625rem;

        @media (max-width: 800px) {
            margin-bottom: 34px !important;
        }
    }
}
