/**
  TYPOGRAPHIC
  global typocraphic constants
**/

// font families

$GTAmericaBlack: "GTAmericaBlack";
$GTAmericaBold: "GTAmericaBold";
$GTAmericaCondensedBold: "GTAmericaCondensedBold";
$GTAmericaMedium: "GTAmericaMedium";
$GTAmericaRegular: "GTAmericaRegular";
$SignifierBlack: "SignifierBlack";
$SignifierBold: "SignifierBold";
$SignifierExtralight: "SignifierExtralight";
$SignifierLight: "SignifierLight";
$SignifierMedium: "SignifierMedium";
$SignifierRegular: "SignifierRegular";
$SignifierThin: "SignifierThin";

/* Font families */
$Lato: 'Lato';
$font-size-default: 18px;

// weights
$font-thin: 300;
$font-normal: 400;
$font-medium: 600; // demi
$font-bold: 700; // bold
$font-bolder: 800; // bolder

$font-weights: (
    regular:400,
    medium: 500,
    semibold: 600,
    bold:700,
    bolder:800,
    boldest: 900
);

// Content

@mixin caption($color: $thunder) {
  @include font(10px, 138%);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($sm) {
    @include caption-xl($color);
  }
}

@mixin caption-xl($color: $thunder) {
  @include font(14px, 133%);

  color: #{$color};
  font-family: $GTAmericaRegular;
}

@mixin anchor($color: $black) {
  @include font(14.5px, 140%);

  @include grid-media($sm) {
    @include font(14.5px, 130%);
  }

  color: #{$color};
  font-family: $GTAmericaBold;
  font-weight: $font-bold;
  position: relative;
}

@mixin oversized-title($color: $black) {
  @include font(68px, 103%);

  color: #{$color};
  font-family: $GTAmericaBold;
  font-weight: $font-bold;

  @include grid-media($xs) {
    @include font(78px, null);
  }

  @include grid-media($sm) {
    @include font(86px, null);
  }

  @include grid-media($lg) {
    @include font(116px, 105%);
  }

  @include grid-media($xl) {
    @include font(134px, null);
  }
}

@mixin body-copy($color: $black) {
  @include font(16px, 160%);

  color: #{$color};
  font-family: $GTAmericaBold;
  font-weight: $font-thin;
  letter-spacing: -0.005em;

  @include grid-media($xs) {
    @include font(18px, 165%);
  }

  @include grid-media($xl) {
    @include font(21px, null);
  }
}

@mixin button($color: $white) {
  color: #{$color};
  font-family: $GTAmericaBold;
  font-size: 18px;
  font-weight: $font-bold;
  line-height: 115%;
}

p,
li {
  @include caption();
}

a {
  @include anchor();
}

button {
  @include button($white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $thunder;
  font-family: $GTAmericaBold;
  font-weight: $font-bold;
}

h1 {
  @include font(32px, 100%);

  @include grid-media($md) {
    @include font(90px, 95px);
  }
}

h2 {
  @include font(44px, 91%);

  font-family: $SignifierRegular;

  @include grid-media($md) {
    @include font(110px, 100%);
  }
}

h3 {
  @include font(32px, 100%, 0.005em);

  @include grid-media($md) {
    @include font(72px, null, null);
  }
}

h4 {
  @include font(22px, 118%, 0.005em);

  @include grid-media($md) {
    @include font(48px, 100%, 0);
  }
}

h5 {
  @include font(14px, 114%, 0.005em);

  @include grid-media($md) {
    @include font(28px, 125%, 0.005em);
  }
}

h6 {
  @include font(20px, 150%, 0.005em);

  @include grid-media($md) {
    @include font(22px, 155%, 0.005em);
  }
}

@mixin h7-xl($color: $grey) {
  @include font(16px, 112%);

  color: #{$color};
  font-family: $GTAmericaRegular;
}

@mixin h8-xl($color: $black) {
  @include font(30px, 133%, 0.005em);

  color: #{$color};
  font-family: $SignifierRegular;

  @include grid-media($md) {
    @include font(82px, 122%);
  }
}

@mixin p1-body-copy($color: $thunder) {
  @include font(24px, 167%, 0.005em);

  color: #{$color};
  font-family: $SignifierRegular;

  @include grid-media($md) {
    @include font(82px, 122%);
  }
}

@mixin p2-body-copy($color: $thunder) {
  @include font(20px, 140%);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($md) {
    @include font(44px, 141%);
  }
}

@mixin p3-body-copy($color: $thunder) {
  @include font(14px, 143%, 0.005em);

  color: #{$color};
  font-family: $GTAmericaBold;

  @include grid-media($md) {
    @include font(22px, 153%, 0.005em);
  }
}

@mixin p4-body-copy($color: $thunder) {
  @include font(16px, 150%);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($md) {
    @include font(20px, 170%);
  }
}

@mixin p5-body-copy($color: $thunder) {
  @include font(14px, 157%);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($md) {
    @include font(20px, 170%);

    font-family: $GTAmericaBold;
  }
}

@mixin p6-body-copy($color: $black) {
  @include font(14px, 157%);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($sm) {
    @include font(16px, 138%);
  }
}

@mixin p-bullet-1($color: $thunder) {
  @include font(14px, 171%);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($sm) {
    @include font(20px, 170%);
  }
}

@mixin p-bullet-2($color: $thunder) {
  @include font(14px, 171%);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($sm) {
    @include font(20px, 170%);
  }
}

@mixin number-list-1($color: $thunder) {
  @include font(8px, 150%, 0.005em);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($sm) {
    @include font(14px, 143%);
  }
}

@mixin bullet-list-1($color: $black) {
  @include font(8px, 150%, 0.005em);

  color: #{$color};
  font-family: $GTAmericaRegular;

  @include grid-media($sm) {
    @include font(18px, 133%);
  }
}

a {
  @include font(16px, 150%);

  color: $green-blue;
  font-family: $GTAmericaRegular;
  transition: $transition-single;

  @include grid-media($sm) {
    @include font(20px, 170%);
  }
}

sup {
  @include font(16px, 150%);

  color: $green-blue;
  font-family: $GTAmericaRegular;

  @include grid-media($sm) {
    @include font(12px, 283%);
  }
}

.underline {
  background-image: linear-gradient(to bottom,
      $green-blue 50%,
      transparent 50%);
  background-position: 0% -90%;
  background-repeat: no-repeat;
  background-size: 100% 200%;
  color: $thunder;
  text-decoration: none;
  transition: all $transition-single ease-out;

  &:hover {
    color: $thunder;
    background-position: 100% 0%;
  }
}