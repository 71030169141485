.big-number {
  @include margin(24px null 16px);

  @include grid-media($sm) {
    @include margin(32px null);
  }

  &__wrapper {
    @include margin(null 0 null auto);
    @include padding(40px null);

    border-bottom: 1px solid $green-blue;
    border-top: 1px solid $green-blue;

    @include grid-media($sm) {
      @include padding(72px null);

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 85px;
    }

    @include grid-media($md) {
      max-width: 90%;
    }
  }

  &__column {
    @include margin(0 null 72px);

    &:last-child {
      @include margin(null null 0);
    }

    @include grid-media($sm) {
      @include margin(null null 0)
    }

    * {
      color: $green-blue;
    }

    dt {
      @include font(48px, 48px);
      @include margin(null null 8px);

      font-family: $GTAmericaBold;
    }

    dd {
      @include margin(null null null 0);

      font-family: $GTAmericaRegular;
    }
  }

  &__content {
    @include grid-media($md) {
      width: 55%;
    }
  }

  &__summary {
    @include caption-xl;
    @include padding(8px null 0);

    border: none;
    color: $gray-light;
    grid-template-columns: none;
  }
}