//*------------------------------------*\
//    $BASE COMPONENT
//*------------------------------------*/

.component {
    position: relative;
    &__background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 0;
    }

    &__content {
        margin: 0 auto;
        padding: 0 30px;
        position: relative;
        max-width: 1340px;
    }

    &__allcaps {
        &:after {
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
            position: absolute;
            clip: rect(0 0 0 0);
            overflow: hidden;
            content: attr(data-caps);
        }
        > [aria-hidden="true"] {
            text-transform: uppercase;
        }
    }

    &__orangehighlight {
        background-color: map-get($colors, orange );
    }

    &__greenhighlight {
        background-color: $darker-green;
    }

    &__redhighlight {
        background-color: map-get($colors, red );
    }

    &__dropdown {
        position: relative;
        select {
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
        }

        .dropdown-select-options {
            cursor: pointer;
            position: absolute;
            width: 100%;
            top: 100%;
            max-height: 0;
            overflow: hidden;
            z-index: 1;
            box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
            font-family: $Lato;
            font-size: px-to-rem(25px);
            font-weight: $font-bolder;
            line-height: px-to-rem(30px);
            .dropdown-select-option {
                padding: px-to-rem(14px) px-to-rem(54px) px-to-rem(13px) px-to-rem(14px);
                border: px-to-rem(2px) solid $white;
                border-top: 0px;
                &.dropdown-select-option-selected {
                    background-color: map-get($colors, blue);
                }

                &:hover, &:focus {
                    background-color: map-get($colors, blue);
                }
            }
        }

        &.dropdown-select-is-open {
            .dropdown-select-options {
                max-height: px-to-rem(300px);
                overflow-y: auto
            }
        }

        .dropdown-select-head {
            cursor: pointer;
            font-family: $Lato;
            font-size: px-to-rem(30px);
            font-weight: $font-bolder;
            line-height: px-to-rem(30px);
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: px-to-rem(10px);
        }
    }
}

.country-template{
    .component {
        &__content {
            @media screen and (min-width: '#{$large-breakpoint}px') {
                margin: 0 auto;
                max-width: 1440px;
            }
        }
    }
}
