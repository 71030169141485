.hero-home {
  background-color: $beige-light;
  display: flex;
  flex-wrap: wrap-reverse;

  @include grid-media($md) {
    height: calc(100vh - 15px);
    flex-wrap: wrap;
    min-height: 600px;
    max-height: 900px;
  }

  &__content {
    @include padding(40px 18px);

    @include grid-media($md) {
      @include padding(null 90px 100px 50px);
      align-self: flex-end;
      max-width: 461px;
      width: 100%;
    }
  }

  &__title {
    @include margin(null null 18px);
    @include font(48px, 48px);
  }

  &__description {
    @include font(20px, 34px);
  }

  &__image {
    @include padding(56.25% null null);
    background-size: cover !important;
    width: 100%;

    @include grid-media($md) {
      @include padding(0 null null);
      width: calc(100% - 461px);
    }
  }
}