.world-map {
  @include padding(51px null 48px);
  background-color: $light-green-1;

  @include grid-media($md) {
    @include padding(78px null 381px);
  }

  &__title {
    @include margin(null null 26px);
    color: rgba($green-blue, 0.53);
    font-family: $SignifierRegular;
    font-weight: $font-normal;

    @include grid-media($md) {
      @include margin(null null 18px);
    }
  }

  &__description {
    @include font(16px, 24px);
    @include margin(null null 51px);
    font-family: $GTAmericaRegular;
    font-weight: $font-normal;

    @include grid-media($md) {
      @include margin(null null 78px);
      @include font(30px, 46px);
    }

    strong {
      font-family: $GTAmericaBold;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    @include grid-media($md) {
      @include margin(null null 62px);
    }
  }

  &__map {
    @include margin(null null 25px);
    width: 100%;

    @include grid-media($md) {
      @include margin(null null 0);
      width: calc(100% - 380px);
    }

    >svg {
      max-width: 100%;
    }

    g {
      &.hoverable {
        path {
          fill: $thunder;
          pointer-events: unset;

          &:hover,
          &.active {
            cursor: pointer;
            fill: $thunder;
          }
        }
      }
    }

    path {
      fill: $green-blue;
      outline: none;
      pointer-events: none;
      stroke: $light-green-1;
      transition: all 0.4s ease;
    }

    circle,
    #ocean,
    #aq {
      display: none;
    }
  }

  &__stats {
    @include margin(null auto);
    display: none;
    max-width: 380px;
    width: 100%;

    @include grid-media($md) {
      @include margin(null 0);
    }

    &[data-code="qa"] {
      display: block;
    }
  }

  &__bottom {
    display: none;

    @include grid-media($md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-title {
      @include margin(null null 8px);
    }

    &-left,
    &-right {
      width: 100%;

      @include grid-media($md) {
        display: flex;
        flex-wrap: wrap;
        max-width: 45%;
      }

      @include grid-media($lg) {
        max-width: 41%;
      }

      p {
        @include font(20px, 34px);
        @include margin(null null 16px);
        font-family: $GTAmericaRegular;
        font-weight: $font-normal;

        strong {
          font-family: $GTAmericaBold;
        }

        &:last-child {
          @include margin(null null 0);
        }

        a {
          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-position: under;
          }
        }
      }

      p:last-child,
      a {
        align-self: flex-end;
        @extend h6;
      }
    }

    &-right {
      @include grid-media($lg) {
        @include margin(null 107px null auto);
      }
    }
  }
}