.issue {
    .component__content {
        margin: 0;
        padding: 95px 50px 92px;
        max-width: 100%;

        @media (max-width: 800px) {
            padding: 56px 18px 48px;
        }

        p{
            font-family: $GTAmericaMedium !important;
        }
    }

    &__heading {
        color: #C05344;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 36px;
        font-family: $GTAmericaMedium;

        @media(max-width:800px) {
            font-size: 32px;
            line-height: 39px;
            letter-spacing: 0.16px;
            margin-bottom: 32px;
        }
    }

    &_description {
        color: #2f2f2f;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 0 !important;

        @media(max-width:800px) {
            color: #000;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.09px;
        }
    }
}