.header {
  .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: $white !important;
    width: 3px;
  }

  .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: $thunder !important;
    width: 3px;
  }
}