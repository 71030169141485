.wysiwyg {
  @include grid-media($md) {
    @include margin(null null null auto);
  }

  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    display: inline-block;
  }

  img {
    @include margin(null null 32px);

    @include grid-media($sm) {
      @include margin(null null 48px)
    }
  }

  p,
  a {
    @include p4-body-copy;
  }

  a {
    color: $green-blue;
    font-family: inherit;
    font-weight: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    @include margin(16px null null);

    @include grid-media($sm) {
      @include margin(48px null null);
    }

    &:last-child {
      @include margin(null null 16px);

      @include grid-media($sm) {
        @include margin(null null 48px);
      }
    }
  }

  h3 {
    @include margin(32px null 16px);

    @include grid-media($sm) {
      @include margin(48px null 32px);
    }
  }

  h4,
  h5 {
    @include margin(24px null 8px);

    @include grid-media($sm) {
      @include margin(32px null 16px);
    }
  }

  h6 {
    @include p3-body-copy($color: $green-blue);
    @include margin(4px null 0);

    @include grid-media($sm) {
      @include margin(8px null);
    }
  }

  ol,
  ul {
    @include margin(16px null 32px 15px);

    @include grid-media($sm) {
      @include margin(16px null 48px null);
    }

    li {
      display: list-item;

      &:not(:last-child) {
        @include margin(null null 8px);
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      @include number-list-1($color: $thunder);
      list-style-position: outside;
    }
  }

  ul {
    list-style-type: disc;

    li {
      @include bullet-list-1($color: $black);
      list-style-position: outside;

      &::marker {
        font-size: 0.75em;
      }
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    @include grid-media($sm) {
      flex-direction: row;
      max-width: 893px;
    }

    @include grid-media($lg) {
      flex-direction: row;
    }
  }

  &__content {
    width: 100%;

    @include grid-media($lg) {
      max-width: 793px;
    }
  }

  &--sidebar {
    .container {
      @include margin(null 0 null auto);

      max-width: 100%;

      @include grid-media($lg) {
        max-width: 1168px;
      }
    }

    .wysiwyg__content {
      @include grid-media($md) {
        max-width: 70%;
      }
    }

    .wysiwyg__sidebar {
      @include margin(16px null null);
      @include padding(8px null null);

      border-top: 2px solid $green-blue;
      width: 100%;

      @include grid-media($md) {
        @include margin(48px null null 41px);

        max-width: calc(30% - 41px);
      }

      @include grid-media($lg) {
        width: calc(100% - 834px);
      }

      a {
        @include font(20px, 24px);

        color: $black;
        font-family: $GTAmericaBold;
        font-weight: $font-normal;
        transition: all 0.4s ease-in-out;

        &:hover {

          &,
          span {
            color: $dark-green;
          }
        }

        span {
          color: $green-blue;
        }
      }
    }
  }
}