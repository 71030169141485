.hero-common {
  @include padding(null null null 20px);

  align-items: center;
  background: $gray-light;
  display: flex;
  height: 180px;
  position: relative;

  @include grid-media($sm) {
    @include padding(null null null 50px);
    height: 360px;
  }

  &__title {
    color: $white;
  }

  &--blog {
    @include margin(null null 40px);
    @include padding(106px null 106px 0);
    align-items: center;
    background-color: $purple;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    text-align: center;

    &-wrapper {
      @include margin(null auto);
      max-width: 1066px;
    }

    &-title {
      @include font(16px, 18px);
      @include margin(0 null 32px);
      color: $white;
      font-family: $GTAmericaRegular;
    }
  }
}