.quote {
  @include margin(24px null);

  @include grid-media($sm) {
    @include margin(32px null);
  }

  * {
    @include font(20px, 28px);

    color: $green-blue;

    @include grid-media($sm) {
      @include font(44px, 62px)
    }
  }

  &__wrapper {
    @include margin(null 0 null auto);
    @include padding(32px null);

    border-bottom: 1px solid $green-blue;
    border-top: 1px solid $green-blue;

    @include grid-media($md) {
      @include margin(null 0 0 auto);

      display: grid;
      row-gap: 0;
      gap: 32px;
      grid-template-columns: 60% 1fr;
      max-width: 90%;
    }
  }

  &__content {
    @include margin(auto null 16px);

    display: flex;
    flex-wrap: wrap;

    @include grid-media($md) {
      @include margin(auto null 0);

      flex-wrap: nowrap;
    }

    &:before {
      @include font(48px, 48px);

      content: '“';
      font-family: $GTAmericaBold;

      @include grid-media($md) {
        @include margin(null 10px null -33px);
      }
    }
  }

  &__author {
    @include p-bullet-1($color: $green-blue);

    font-family: $GTAmericaBold;
    width: 100%;

    &-content {
      display: flex;
      flex-wrap: wrap;

      @include grid-media($sm) {
        @include margin(auto null null);

        display: unset;
      }

      small {
        display: block;
      }
    }

    &-details {
      @include p6-body-copy($color: $green-blue);
    }
  }
}