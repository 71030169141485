.cta-newsletter {
  @include margin(24px null);

  @include grid-media($sm) {
    @include margin(32px null);
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;

    @include grid-media($sm) {
      align-items: center;
      background: $light-green;
      background: linear-gradient(90deg,
          rgba(227, 241, 240, 1) 0%,
          rgba(227, 241, 240, 1) 0%,
          rgba(227, 241, 240, 1) 92.5%,
          rgba(255, 255, 255, 0) 92.5%,
          rgba(255, 255, 255, 0) 100%);
      flex-direction: row;
    }
  }

  &__form {
    @include padding(46px 48px 46px 32px);

    background: $light-green;

    @include grid-media($sm) {
      @include padding(32px 38px 38px 32px);

      background: none;
      width: calc(100% - 123px);
    }

    p {
      @include p3-body-copy($color: $black);
      @include margin(null null 10px);

      span {
        display: block;

        @include grid-media($sm) {
          display: unset;
        }
      }
    }

    .mc4wp-form-fields {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include grid-media($md) {
        flex-direction: row;
      }
    }

    input {
      @include font(16px, 24px);
      @include padding(null 5px);
      margin-bottom: 16px;

      border: none;
      color: $black;
      height: 28px;
      width: 100%;

      @include grid-media($sm) {
        @include font(20px, 34px);
        @include padding(null 14px);

        font-family: $GTAmericaRegular;
        height: 50px;
        max-width: 507px;
      }

      &[type="submit"] {
        @include font(20px, 34px);

        align-items: center;
        cursor: pointer;
        display: flex;
        background: $green-blue;
        border: none;
        color: $white;
        font-family: $GTAmericaRegular;
        font-weight: $font-normal;
        height: 52px;
        justify-content: center;
        max-width: 123px;
        transition: all .4s ease-in-out;
        width: 100%;

        @include grid-media($md) {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          background: $dark-green;
        }
      }
    }

    ::-webkit-input-placeholder {
      color: $black;
      font-family: $GTAmericaRegular;
    }

    :-ms-input-placeholder {
      color: $black;
      font-family: $GTAmericaRegular;
    }

    ::placeholder {
      color: $black;
      font-family: $GTAmericaRegular;
    }
  }
}