.publish-details {
  @include margin(null null 32px 0);
  @include padding(null null 32px);

  border-bottom: 1px solid $black;

  @include grid-media($sm) {
    @include margin(null null 52px 0);
    @include padding(null null 48px);
  }

  * {
    @include font(14px, 22px);

    color: $black;
    font-family: $GTAmericaBold;
    font-weight: $font-normal;

    span,
    time {
      font-family: $GTAmericaRegular;
    }

    @include grid-media($sm) {
      @include font(16px, 22px);
    }
  }

  .container {
    text-align: center;

    @include grid-media($md) {
      text-align: unset;
    }
  }

  ul {
    @include margin(null auto);
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include grid-media($sm) {
      display: inline-flex;
      align-items: unset;
      flex-direction: row;
    }

    li {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;

      &:not(:last-child) {
        @include grid-media($sm) {
          @include margin(null 50px null null);
        }
      }
    }
  }
}
