.rank-math-breadcrumb {
  @include padding(18px);
  background: $light-green;

  @include grid-media($sm) {
    @include padding(10px 50px);
  }

  a,
  span {
    font-size: 14px !important;

    @include grid-media($sm) {
      font-size: 16px !important;
    }
  }

  a {
    &:not(:first-child) {
      padding-left: 10px;
    }
  }

  span {
    padding-left: 10px;
  }
}
