.death__and__economic__costs {    
    .component__content {
        align-items: center;
        display: grid;
        font-family: $GTAmericaMedium;
        grid-template-columns: 1fr 1fr;
        margin: 0;
        max-width: 100%;
        padding: 0 50px;
        width: 100%;
        grid-gap: 68px;

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
            padding: 16px 18px 48px;
            grid-gap: 32px;
        }

    }

    &__image {
        img {
            width: 100%;
            object-fit: contain;
        }
    }

    &.colored_bg {
        background-color: #F8F7F3;
        border-top: 1px solid #C05344;
        border-bottom: 1px solid #C05344;
    }

    .colored_bg {
        .component__background {
            background-image: none !important;
        }
    }

    &__title {
        color: #C05344;
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        @media(max-width:800px) {
            font-size: 20px;
            line-height: 26px;
            letter-spacing: 0.1px;
            margin-bottom: 16px;
        }
    }

    &__value {
        font-family: $GTAmericaMedium;
        font-size: 60px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: 0.36px;

        @media (max-width: 800px) {
            font-size: 48px;
            line-height: 48px;
            letter-spacing: 0.24px;
            margin-bottom: 48px;
        }

        &.cost {
            margin-bottom: 0;

            @media (max-width: 800px) {
                font-size: 22px;
                line-height: 48px;
                letter-spacing: 0.11px;
                margin-bottom: 16px;
            }
        }
    }

    &__currency {
        font-size: 30px;
        font-family: $GTAmericaMedium;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0.15px;
        margin-bottom: 2.625rem;

        @media (max-width: 800px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0 !important;
        }
    }

    .info__div {
        margin-top: 48px;

        @media (max-width: 800px) {
            margin-top: 0;
        }
    }
}
