/*Hamburger-Menu*/

.hamburger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 4px;
  background: $white;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.hamburger {
  cursor: pointer;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 22px;

  @include grid-media($md) {
    height: 22px;
  }
}

.hamburger span:nth-child(even) {
  left: 38%;
  border-radius: 50%;
}

.hamburger span:nth-child(odd) {
  left: 0px;
  border-radius: 50%;
}

.hamburger span:nth-child(1), .hamburger span:nth-child(2), .hamburger span:nth-child(3) {
  top: 0px;
}

.hamburger span:nth-child(4), .hamburger span:nth-child(5), .hamburger span:nth-child(6) {
  top: 10px;
}

.hamburger span:nth-child(7), .hamburger span:nth-child(8), .hamburger span:nth-child(9) {
  top: 20px;
}

.hamburger span:nth-child(3) {
  left: 75%;
}

.hamburger span:nth-child(6) {
  left: 75%;
}

.hamburger span:nth-child(9) {
  left: 75%;
}

// .hamburger.open {
//   height: 35px;
// }

.hamburger.open span:nth-child(1) {
  border-radius: 0;
  left: -4px;
  top: 6px;
  transform: rotate(45deg);
  width: 15px;
  height: 3px;
}

.hamburger.open span:nth-child(3) {
  border-radius: 0;
  left: 30%;
  top: 6px;
  transform: rotate(-45deg);
  width: 15px;
  height: 3px;
}

.hamburger.open span:nth-child(7) {
  border-radius: 0;
  left: -5px;
  top: 16px;
  transform: rotate(-45deg);
  width: 15px;
  height: 3px;
}

.hamburger.open span:nth-child(9) {
  border-radius: 0;
  left: 30%;
  top: 16px;
  transform: rotate(45deg);
  width: 15px;
  height: 3px;
}

.hamburger.open span:nth-child(2) {
  top: 10px;
  height: 0;
  width: 0;
}

.hamburger.open span:nth-child(4) {
  left: 5.4px;
  height: 0;
  width: 0;
}

.hamburger.open span:nth-child(5) {
  left: 35%;
  top: 12px;
  height: 0;
  width: 0;
}

.hamburger.open span:nth-child(6) {
  left: 35%;
  height: 0;
  width: 0;
}

.hamburger.open span:nth-child(8) {
  top: 10px;
  left: 5px;
  height: 0;
  width: 0;
}