.big-number-solid {
  @include padding(64px null);
  background: $green-blue;
  text-align: center;

  @include grid-media($sm) {
    @include padding(80px null);
  }

  * {
    color: $white;
  }

  &__wrapper {
    @include margin(null 0);
    @include padding(64px null null);

    @include grid-media($sm) {
      @include padding(64px null null);

      display: grid;
      grid-template-rows: repeat(3, 1fr);
      gap: 64px;
    }
  }

  &__column {
    @include margin(0 null 50px);

    &:last-child {
      @include margin(null null 0);
    }

    @include grid-media($sm) {
      @include margin(null null 0)
    }

    dt {
      @include font(32px, 32px);
      @include margin(null null 8px);

      font-family: $GTAmericaBold;

      @include grid-media($md) {
        @include font(72px, 72px);
      }
    }

    dd {
      @include font(20px, 34px);
      @include margin(null null null 0);

      font-family: $GTAmericaRegular;
    }
  }

  &__content {
    @include margin(null auto);
    max-width: 509px;

    h5 {
      @include margin(null null 8px);
      text-transform: uppercase;
      letter-spacing: 0.15em;

      @include grid-media($sm) {
        @include margin(null null 0);
      }
    }

    p {
      @include font(16px, 24px);

      @include grid-media($sm) {
        @include font(20px, 34px);
      }
    }
  }

  &__summary {
    @include caption-xl;
    @include padding(8px null 0);

    border: none;
    color: $gray-light;
    grid-template-columns: none;
  }
}