.search {
  @include margin(32px null null);

  @include grid-media($sm) {
    @include margin(64px null null);
  }

  .featured {
    @include margin(null null 92px);

    @include grid-media($md) {
      @include margin(null null 64px);
    }

    .container {
      @include grid-media($md) {
        @include padding(null 183px null null);
      }
    }

    &__title {
      @include margin(0 null 24px);

      @include grid-media($md) {
        @include margin(0 null 32px);
      }
    }

    &__col {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      &:not(:last-child) {
        @include padding(null null 76px);
        border-bottom: 1px solid rgba($black, 0.25);

        @include grid-media($md) {
          @include padding(null 16px 0 null);
          border-bottom: none;
          border-right: 1px solid rgba($black, 0.25);
        }
      }

      &-wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;

        @include grid-media($md) {
          @include padding(32px null);
          border-top: 1px solid rgba($black, 0.25);
          border-bottom: 1px solid rgba($black, 0.25);
          grid-template-columns: repeat(3, 1fr);
          gap: 32px;
        }
      }
    }

    &__title,
    &__col-title {
      @include font(22px, 34px);
      font-family: $GTAmericaBold;
    }

    &__col-subtitle,
    &__col-category {
      @include font(16px, 24px);
      color: $light-brown;
      font-family: $GTAmericaRegular;
      font-weight: $font-normal;
    }

    &__col-title {
      @include margin(null null 24px);
    }

    &__col-subtitle {
      @include margin(0 null 8px);
    }

    &__col-category {
      @include margin(auto null null);
      text-transform: uppercase;
    }
  }

  .wysiwyg {
    .container {
      max-width: 1440px;
    }
  }

  .wysiwyg__content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;

    .search__result {
      @include padding(28px null);
      border-top: 1px solid $light-brown;
      display: flex;
      flex-direction: column;

      &:last-child {
        border-bottom: 1px solid $light-brown;
      }

      @include grid-media($md) {
        @include padding(28px null);
      }

      &-subtitle {
        @include margin(0 null 8px);
        @include font(16px, 18px);
        color: $light-brown;
        font-family: $GTAmericaRegular;
        font-weight: $font-normal;
      }

      &-title {
        @include margin(0 null 8px);
        @include font(22px, 34px);
        color: $dark-gray;
        font-family: $GTAmericaBold;
      }

      &-cat {
        @include margin(0 null 8px);
        @include font(16px, 18px);
        color: $light-brown;
        font-family: $GTAmericaRegular;
        text-transform: uppercase;
      }

      &-columns {
        display: grid;

        @include grid-media($sm) {
          column-gap: 26px;
          grid-template-columns: 85% auto;
        }
      }
    }
  }

  &__left {
    width: 100%;

    @include grid-media($md) {
      max-width: 320px;
    }

    .filter {
      @include margin(null null 20px);
      @include padding(null null 10px);
      border-bottom: 1px solid rgba($black, 0.25);
      display: flex;
      justify-content: space-between;
      width: 100%;

      * {
        color: $thunder;
      }

      p,
      a {
        @include margin(0);
      }

      p {
        margin-bottom: 0 !important;
      }

      &__title {
        @include margin(0);
        @include font(22px, 34px);
      }

      &-clear {
        @include font(20px, 34px);
      }
    }

    .facetwp-facet {
      display: none;
    }

    .accordion-button {
      @include margin(null null 28px);
      @include padding(0);
      @include font(22px, 34px, 0.005em);
      color: $thunder;
      display: flex;
      font-family: $GTAmericaBold;
      justify-content: space-between;
      width: 100%;

      @include grid-media($sm) {
        @include margin(null null 20px);
      }

      &:after {
        content: "\002B";
        transition: all 0.4s ease;
      }

      &.active {
        &:after {
          content: "\2014";
        }
      }
    }

    .facetwp-checkbox {
      @include font(20px, 34px);
      color: $thunder;
      font-family: $GTAmericaRegular;

      &:not(:last-child) {
        @include margin(null null 16px);
      }

      &:last-child {
        @include margin(null null 0);
      }
    }

    .facetwp-toggle {
      font-family: $GTAmericaRegular;
    }
  }

  &__right {
    width: 100%;

    @include grid-media($md) {
      @include margin(null null null 90px);
      @include padding(null 133px null null);
      width: calc(100% - 410px);
    }

    .fwpl-layout {
      grid-gap: 0;
    }

    .fwpl-result {
      @include margin(null null 11px);
      @include padding(0 0 47px);
      background: none;
      border: none;
      border-bottom: 1px solid $light-brown;
      font-family: $GTAmericaBold;

      @include grid-media($md) {
        @include margin(null null 28px);
        @include padding(0 0 32px);

        &:last-child {
          @include margin(null null 0);
          @include padding(null null 0);
          border: none;
        }
      }

      &,
      a {
        color: $thunder;
      }
    }

    .facetwp-facet.is-loading {
      opacity: 1 !important;
    }

    .facetwp-pager {
      @include padding(24px null null);
      width: 100%;

      a {
        @include font(20px, 24px);
        @include margin(null 15px null null);
        color: $thunder;
        font-family: $GTAmericaRegular;

        &:hover,
        &:focus {
          color: $green-blue;
          opacity: 1 !important;
        }

        &.next,
        &.prev {
          @include padding(null 0);
          color: transparent;
          overflow: hidden;
          vertical-align: bottom;
          white-space: nowrap;
          width: 26px;

          &:before {
            color: $thunder;
            font-size: 1.2em;
          }
        }

        &.next {
          &:before {
            content: "\2192";
          }
        }

        &.prev {
          &:before {
            content: "\2190";
          }
        }

        &.active {
          color: $green-blue;
          font-family: $GTAmericaBold;
        }
      }
    }

    .facetwp-type-autocomplete,
    .facetwp-type-search {
      position: relative;

      .facetwp-input-wrap {
        width: 100%;
      }

      input {
        &[type="text"] {
          @include padding(8px 16px 8px 54px);
          border: 1px solid $green-blue;
          color: $green-dark;
          width: 100%;

          &::placeholder {
            font-family: $GTAmericaRegular;
            color: $green-dark;
          }
        }
      }

      .facetwp-icon {
        opacity: 1;

        &:before {
          content: none;
          display: none;
        }
      }

      .facetwp-icon,
      input[type="button"] {
        @include padding(null 0 null 13px);
        background: url(#{$themeimages}/search-icon-green.svg) center center no-repeat;
        border: none;
        color: transparent;
        height: 100%;
        left: 0;
        position: absolute;
        width: 45px;
      }
    }
  }

  .download-files {
    @include margin(0);

    @include grid-media($sm) {
      @include margin(0 0 0 auto);
    }

    * {
      @include font(16px, 32px, -0.01em);
      color: $thunder;
    }

    li {
      display: flex;

      a {
        &:not(:last-child) {
          @include margin(null 18px null null);
        }
      }
    }

    img {
      @include margin(0 auto);
    }
  }
}