.reference-bar {
  @include padding(32px null);
  background: $off-white;

  @include grid-media($md) {
    @include padding(64px null);
  }

  * {
    @include number-list-1($color: $thunder);
  }

  p {
    margin-bottom: 16px;
  }

  ol,
  ul {
    @include margin(null null null 15px);

    li {
      display: list-item;

      &:not(:last-child) {
        @include margin(null null 8px);
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      list-style-position: outside;
    }
  }

  ul {
    list-style-type: disc;

    li {
      @include bullet-list-1($color: $black);
      list-style-position: outside;

      &::marker {
        font-size: 0.75em;
      }
    }
  }

  .js-custom-scroll {
    max-height: 280px;

    .mCSB_scrollTools .mCSB_draggerContainer {
      border-radius: 30px !important;
    }

    .mCSB_scrollTools {
      background: $white;
      border-radius: 30px !important;
      opacity: 1 !important;
      width: 11px !important;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background: $light-brown  !important;
      opacity: 1 !important;
      width: 11px !important;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
      display: none;
    }
  }
}