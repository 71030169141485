.latest-posts {
  @include padding(40px null 64px);
  background-color: $beige-light;

  @include grid-media($md) {
    @include padding(76px null 38px);
  }

  &__link {
    display: none;
    color: $thunder;
    font-family: $GTAmericaBold;

    @include grid-media($md) {
      display: block;
    }
  }

  &__title {
    @include margin(null null 20px);
    @include font(44px, 40px);
    font-family: $SignifierRegular;
    font-weight: $font-normal;

    @include grid-media($md) {
      @include margin(null null 32px);
      @include font(110px, 110px);
    }
  }

  &__post {
    @include padding(24px null);
    border-top: 1px solid $light-brown;

    &:last-of-type {
      border-bottom: 1px solid $light-brown;

      @include grid-media($md) {
        @include margin(null null 16px);
      }

      .latest-posts__post-title {
        @include margin(null null 16px);

        @include grid-media($md) {
          @include margin(null null 28px);
        }
      }
    }

    @include grid-media($md) {
      @include padding(38px null);
      column-gap: 8%;
      display: grid;
      grid-template-columns: 40% 1fr;
    }

    &-left {
      @include grid-media($md) {
        align-items: flex-end;

        &,
        a {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 100%;
        }
      }


      time {
        @include margin(null null 8px);
        display: block;
        justify-content: flex-start;
      }

      summary {
        @include grid-media($md) {
          @include margin(auto null null);
        }
      }
    }

    &-title {
      @include margin(0 null 24px);
      @include font(14px, 20px, .005em);
      color: $thunder;
      display: block;
      font-family: $GTAmericaBold;

      &:hover {
        text-decoration: underline;
        text-underline-position: under;
      }

      @include grid-media($md) {
        @include font(30px, 46px);
        @include margin(0 null 28px);
      }
    }

    &-time {
      @include margin(null null 8px);
      @include p3-body-copy($color: $light-brown);

      @include grid-media($md) {
        @include margin(null null 0);
      }
    }

    &-cat {
      font-weight: $font-normal;
    }


    &-cat,
    &-author {
      @include font(12px, 18px);
      color: $light-brown;
      font-family: $GTAmericaRegular;

      @include grid-media($md) {
        @include font(16px, 18px);
      }
    }

    &-author {
      display: none;
      max-width: 120px;

      @include grid-media($md) {
        display: block;
        max-width: 160px;
      }

      span {
        font-family: $GTAmericaBold;
      }

      &--mobile {
        @include margin(null null 16px);
        display: block;

        @include grid-media($md) {
          display: none;
        }
      }
    }
  }
}