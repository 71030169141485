.country__with__buttons {
    .component__content {
        margin: 0;
        padding: 62px 50px 55px;
        max-width: 100%;

        @media (max-width: 800px) {
            padding: 48px 18px 64px;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;

        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: 100%;
        }
    }

    p {
        font-family: $GTAmericaMedium;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        color: #C05344;
        margin: 0;

        @media (max-width: 800px) {
            font-size: 32px;
            line-height: 32px;
            letter-spacing: 0.16px;
            margin-bottom: 32px;
        }
    }

    .fact__sheet__button {
        border: 2px solid #C05344;
        margin-left: 4%;
        font-weight: 700;
        font-size: 20px;
        line-height: 34px;
        text-transform: none;
        align-items: center;
        text-align: center;
        color: #C05344;

        @media (max-width: 800px) {
            margin-top: 20px;
        }

        &__grid {
            display: flex;
            align-items: center;
            justify-content: right;
            gap: 36px;

            @media (max-width: 800px) {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 5px 0;
            }
        }
    }

    .view-source__button {
        font-family: $GTAmericaMedium;
        text-align: center;

        &:last-child{
            @media (max-width: 800px){
                margin-bottom: 0;
            }
        }
    }
}
