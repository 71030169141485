.bans {
    font-family: $GTAmericaRegular;

    .component__content {
        margin: 0;
        padding: 0 50px;
        max-width: 100%;

        @media (max-width: 800px) {
            padding: 0 18px;
        }
    }

    &__heading {
        color: #17AEA5;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        margin-top: 44px;
        margin-bottom: 24px;
        font-family: $GTAmericaBold;

        @media (max-width: 800px) {
            font-size: 24px;
            line-height: normal;
            margin-top: 48px;
            margin-bottom: 32px;
        }
    }

    &__description {
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 44px;

        @media (max-width: 800px) {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.09px;
            margin-bottom: 61px;
        }
    }

    &__section1 {
        border-top: 1px solid #2f2f2f;
    }

    &__section2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 34px;
        margin-bottom: 72px;

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
            margin-bottom: 43px;
            grid-gap: 45px;
        }
    }

    .section2 {
        &__heading {
            font-family: $GTAmericaBold;
            font-size: 30px;
            font-weight: 700;
            line-height: 46px;
            margin: 0;

            @media (max-width: 800px) {
                font-size: 20px;
                line-height: 26px;
                letter-spacing: 0.1px;
            }
        }

        &__description {
            font-family: $GTAmericaRegular;
            float: right;
            font-size: 20px;
            font-weight: 400;
            line-height: 46px;
            letter-spacing: normal;

            @media (max-width: 800px) {
                margin: 0;
                float: none;
                display: block;
                width: 100%;
                font-size: 16px;
                line-height: 24px;
            }
        }

        &__header{
            padding-bottom: 52px;

            @media (max-width: 800px){
                padding-bottom: 23px;
            }
        }
    }

    &__source {
        color: #2f2f2f;
        font-family: $GTAmericaRegular;
        font-size: 20px;
        font-weight: 400;
        line-height: 34px;
        text-align: right;
        margin-top: 31px;

        @media (max-width: 800px) {
            display: none;
        }
    }

    &__ad-compliance {
        background: rgba(23, 174, 165, 0.12);
        padding: 59px 134px;
        margin-top: 66px;

        p {
            color: #17AEA5;
            font-family: $GTAmericaMedium;
            font-size: 30px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0;
            text-align: center;

            span {
                font-family: $GTAmericaRegular;
                font-weight: 400;
                margin-top: 23px;
                display: block;

                @media (max-width: 800px) {
                    font-size: 20px;
                }
            }

            @media (max-width: 800px) {
                font-size: 24px;
            }
        }

        &.desktop {
            display: block;

            @media (max-width: 800px) {
                display: none;
            }
        }

        &.mobile {
            display: none;

            @media (max-width: 800px) {
                display: block;
            }
        }

        @media (max-width: 800px) {
            padding: 60px 25px;
            margin-top: 65px;
            text-align: center;
        }
    }
}

.ban {
    &__item {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: 0 98px;
        border-bottom: 1px solid #2f2f2f;
        font-weight: 700;
        font-size: 20px;
        margin: 0;
        padding-top: 24px;
        padding-bottom: 24px;

        &:first-child{
            padding-top: 0;
        }

        &__description {
            font-family: $GTAmericaBold;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 0 !important;

            @media (max-width: 800px) {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px !important;
            }
        }

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
            padding-top: 16px;
            padding-bottom: 8px;
        }

        &__boolean {
            float: right;
            padding-right: 10%;
            color: $maroon;
            font-family: $GTAmericaBold;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;

            &.yes {
                color: #17AEA5;
                padding: 0;
            }

            &.na {
                color: #bebebe;
            }

            @media (max-width: 800px) {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
            }

        }
    }
}
