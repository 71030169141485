.harms {
    .component__content {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        margin: 5% auto;

        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: 100%;
        }

    }

    &__content {
        width: 95%;
        height: 100%;
        margin-bottom: 0;
        margin-right: 96px;
        background-color: #C05344;
        padding: 5%;
    }

    &__heading {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 28px;
    }

    &__description {
        color: #FFFFFF;
        font-size: 18px;
    }

    .deaths {
        &__heading {
            color: #C05344;
            font-weight: 700;
            font-size: 28px;
            margin: 0;
        }

        &__content {
            border-top: 1px solid #C05344;
            padding: 5%;

            .info__div {
                @media (max-width: 800px) {
                    display: grid;
                    grid-template-columns: 100%;
                    grid-gap: 0;
                }
            }

            @media (max-width: 800px) {
                border: none;
                padding: 5%;
            }
        }

        &__content .info__div {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-gap: 40px;
        }

        &__gender {
            font-weight: 700;
            margin: 0;
        }

        &__percentage {
            color: #C05344;
            font-weight: 700;
            font-size: 48px;
            margin: 10px 0;
        }

        &__description {
            margin-right: 15%;
        }
    }
}
