/**
* Site-wide layout styling
*/

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
}

body {
  @include padding(60px null null);

  overflow-x: hidden;

  @include grid-media($md) {
    @include padding(78px null null);
  }

  &.logged-in {
    @include padding(14px null null);

    @include grid-media($sm) {
      @include padding(25px null null);
    }

    @include grid-media($md) {
      @include padding(46px null null);
    }
  }
}

* {
  box-sizing: border-box;
}

.body-hidden {
  overflow-y: hidden;
}

section {
  display: inline-block;
  position: relative;
  width: 100%;

  @include grid-container;
}

.container {
  @include grid-container;
  @include margin(0 auto);
  @include padding(null 18px);

  &:after {
    content: "";
    display: none;
  }

  max-width: $container-width;
  width: 100%;

  @include grid-media($sm) {
    @include padding(null 50px);
  }

  &--small {
    @include grid-media($sm) {
      max-width: 893px;
    }
  }
}

// fix for input line-height issues on Safari
input::-webkit-input-placeholder {
  line-height: normal !important;
}

.inline-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include grid-media($sm) {
    flex-direction: row;
  }
}

img {
  display: block;
}
