.footer {
  @include padding(46px null 23px);

  background-color: $thunder;

  @include grid-media($md) {
    @include padding(50px null 81px);
  }

  a {
    transition: all .3s ease;

    &:hover,
    &:focus {
      color: $blue-chill;
      text-decoration: underline;
    }
  }

  &__wrapper {
    @include grid-media($md) {
      @include margin(null null 34px);

      border-bottom: 1px solid $white;
      display: flex;
      flex-direction: row;
    }
  }

  &__logo {
    @include margin(null null 21px);
    height: fit-content;

    @include grid-media($md) {
      @include margin(null null 42px);
    }

    &-link {
      display: block;
      width: max-content;
    }

    &-img {
      height: 100%;
      max-width: 147px;
      object-fit: cover;
      width: 100%;

      @include grid-media($md) {

        max-width: 217px;
      }
    }
  }

  &__left {
    @include margin(null null 60px);

    @include grid-media($md) {
      @include margin(null 63px 55px null);
    }

    @include grid-media($lg) {
      @include margin(null 93px null null);
    }

    &-item {
      .footer__left-link {
        &:hover {
          text-decoration: none;
        }
      }
    }

    &-link {
      @include font(48px, 111%, -0.022em);

      color: $white;
      font-family: $GTAmericaBlack;

      @include grid-media($lg) {
        @include font(60px, 109%, -0.022em);
      }
    }
  }

  &__center {
    @include margin(null null 60px);

    @include grid-media($lg) {
      @include margin(null null 0);
    }

    &-title {
      @include font(24px, 200%, -0.022em);
      @include margin(null null 15px);

      color: $white;
      border-bottom: 1px solid $white;
      font-family: $GTAmericaBlack;

      @include grid-media($md) {
        @include font(19px, 33.5px, -0.022em);
        @include margin(null null 6px);
      }
    }

    &-link {
      @include font(24px, 142%, -0.022em);

      color: $white;
      font-family: $GTAmericaRegular;
      font-weight: $font-normal;
    }
  }

  &__center,
  &__right {
    @include grid-media($md) {
      @include margin(null 20px null null);

      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      max-width: 320px;
      width: 100%;
    }
  }

  &__right {
    &-col-1 {
      @include margin(null null 50px);
    }

    &-col-2 {
      @include margin(null null 22px);

      border-bottom: 1px solid $white;

      .footer__right-items {
        @include margin(null null 50px);

        @include grid-media($lg) {
          @include margin(null null 46px);
        }
      }

      @include grid-media($md) {
        @include margin(null 0 20px null);

        border-bottom: none;
      }
    }

    &-title {
      @include font(19px, 33.5px, -0.022em);
      @include margin(null null 10px);
      @include padding(null null 6px);

      color: $white;
      border-bottom: 1px solid $white;
      font-family: $GTAmericaBlack;

      @include grid-media($md) {
        @include padding(null null 0);
      }
    }

    &-items {
      columns: 2;
      column-gap: 35px;
    }

    &-link {
      @include font(15px, 178%);

      color: $white;
      font-family: $GTAmericaRegular;
    }

    &-article {
      @include margin(auto null 5px);

      align-items: center;
      display: flex;
      justify-content: flex-end;

      &--text {
        @include font(16px, 1, 0.35px);
        @include margin(null 16px null null);

        color: $white;
        font-family: $GTAmericaRegular;

        @include grid-media($md) {
          @include font(20px, null, null);
        }
      }

      &--link {
        @include font(20px, 1, -0.022em);

        color: $white;
        font-family: $GTAmericaBold;
        text-transform: uppercase;
      }
    }

    &-list {
      padding-bottom: 10px;

      @include grid-media($sm) {
        padding-bottom: 0;
      }
    }
  }

  &__content-wrapper {
    @include grid-media($md) {
      @include margin(null null 50px);

      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include grid-media($lg) {
      @include margin(null null 50px);

      grid-template-columns: 642px 1fr;
    }
  }

  &__content {
    &-description {
      @include font(13px, 154%, -0.022em);
      @include margin(null null 22px);

      color: $white;
      font-family: $GTAmericaMedium;

      @include grid-media($lg) {
        @include font(16px, 137%, -0.022em);
        @include margin(null null 0);
      }
    }
  }

  &__logos {
    @include margin(null null 26px);

    display: flex;
    flex-direction: column;
    max-width: 164px;
    width: 100%;

    @include grid-media($md) {
      @include margin(null null 0 auto);

      align-items: center;
      align-self: center;
      flex-direction: row;
      max-width: max-content;
      width: 100%;
    }

    a:first-child {
      img {
        @include margin(null null 24px);

        @include grid-media($md) {
          @include margin(null 20px null null);
        }

        @include grid-media($lg) {
          @include margin(null 40px 0 null);
        }
      }
    }
  }

  &__bottom {
    @include grid-media($md) {

      display: flex;
      justify-content: space-between;
    }

    &-link,
    &-item {
      @include font(16px, 162%, -0.022em);

      color: $white;
      font-family: $GTAmericaBold;

      @include grid-media($md) {
        @include font(20px, null, null);
      }
    }

    &-items {
      @include grid-media($md) {
        display: flex;
      }

      li {
        &:first-child {
          @include margin(null null 21px);

          @include grid-media($md) {
            @include margin(null 30px 0 null);
          }

          @include grid-media($lg) {
            @include margin(null 60px 0 null);
          }
        }

        &:last-child {
          @include margin(null null 14px);

          @include grid-media($md) {
            @include margin(null null 0);
          }
        }
      }
    }

    &-copyright {
      &--text {
        @include font(16px, 137%, -0.022em);

        color: $white;
        font-family: $GTAmericaMedium;
      }
    }
  }
}
