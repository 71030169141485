.dual-image {
  .container {
    @include grid-media($sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
    }

    figure {
      @include margin(16px null);

      @include grid-media($sm) {
        @include margin(32px null);
      }
    }
  }

  &__title {
    @include margin(null null 28px);

    @include grid-media($sm) {
      @include margin(null null 48px);
    }
  }

  &__img {
    object-fit: cover;
  }

  &__caption {
    @include margin(8px null null);
    @include caption;
    color: $gray-light  !important;

    @include grid-media($sm) {
      @include margin(16px null null);
    }
  }
}