.dual-charts {
  .container {
    @include grid-media($sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
    }

    figure {
      @include margin(32px null 24px);

      @include grid-media($sm) {
        @include margin(48px null 32px);
      }
    }
  }

  &__img {
    object-fit: cover;
  }
}
