.hero-evergreen {
  @include padding(56.25% null null);
  height: 0;
  position: relative;

  @include grid-media($sm) {
    @include padding(0 null null);
    height: 618px;
  }

  &__image {
    background-size: cover !important;
    display: none;
    height: 100%;
    width: 100%;

    @include grid-media($sm) {
      display: block;
    }

    &--mobile {
      display: block;
      left: 0;
      position: absolute;
      top: 0;

      @include grid-media($sm) {
        display: none;
      }
    }
  }

  &__text {
    @include padding(130px null null);
  }

  &__title {
    @include margin(null null 24px);

    @include grid-media($sm) {
      max-width: 380px;
    }

    @include grid-media($md) {
      max-width: unset;
    }
  }

  &__subtitle {
    @include p3-body-copy($color: $light-brown);
    @include margin(null null 6px);
  }

  * {
    color: $white;
  }
}