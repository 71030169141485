.single-features {
  background: $beige;

  .addtoany_shortcode {
    .a2a_kit {
      @include margin(0 auto 32px);
      display: flex;
      align-self: flex-start;
      justify-content: center;

      @include grid-media($sm) {
        @include margin(0);
      }

      >a {
        @include padding(0);

        span {
          margin: 0 20px 0 0 !important;

          @include grid-media($sm) {
            margin: 0 16px 0 0 !important;
          }
        }

        &:last-child {
          span {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .inline-wrapper {
    @include padding(null 18px);

    @include grid-media($sm) {
      @include margin(null auto 102px);
      @include padding(null 50px);

      max-width: 1166px;
    }

    .publish-details {
      align-items: center;
      display: flex;
      width: auto;

      @include grid-media($sm) {
        @include margin(null null 0 auto);
        @include padding(null null 0);

        border: none;
      }

      .container {
        @include padding(null 0);
        max-width: 100%;
      }
    }
  }

  .wysiwyg {
    @include grid-media($md) {
      @include margin(null null 48px);
    }
  }
}