.big-statement {
  @include margin(null null 64px);
  
  @include grid-media($sm) {
    @include margin(null null 136px);
    max-width: 1020px;
  }

  * {
    color: $black;
  }

  &__title {
    @include h8-xl;
    font-weight: $font-normal;
  }

  &__subtitle {
    text-transform: uppercase;
  }

  &--invert {
    @include grid-media($sm) {
      display: flex;
      max-width: 890px;
      @include margin(null null null auto);
    }
  }
}