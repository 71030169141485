.subscribe {
  &__form {
    border: 1px solid $white;

    ::-webkit-input-placeholder {
      color: $white;
      font-family: $GTAmericaRegular;
    }

    :-ms-input-placeholder {
      color: $white;
      font-family: $GTAmericaRegular;
    }

    ::placeholder {
      color: $white;
      font-family: $GTAmericaRegular;
    }
  }

  &__input {
    @include font(16px, 24px);
    font-family: $GTAmericaRegular;
  }

  form {
    align-items: center;
    display: flex;

    &.mc4wp-form-success {
      &:after {
        background: url('assets/img/done-icon.svg') right center no-repeat;
        content: '';
        height: 48px;
        position: absolute;
        right: 15px;
        width: 48px;
      }

      .mc4wp-form-fields {
        display: none;
      }
    }

    .mc4wp-response {
      p {
        @include font(16px, 24px);
        color: $white;
        font-family: $GTAmericaRegular;

        @include grid-media($md) {
          @include font(30px, 46px);
        }
      }
    }
  }
}