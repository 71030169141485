.chapters {
  &__hero {
    @include margin(-15% null 78px);
  }

  &__title {
    border-bottom: 1px solid $black;
    font-family: $SignifierRegular;
    font-weight: $font-normal;
    @include font(44px, 40px);
    @include margin(null null 26px);
    @include padding(null null 15px);

    @include grid-media($md) {
      @include font(110px, 110px);
      @include margin(0 -50px 32px);
      @include padding(0 50px 32px);
    }
  }

  &__grid {
    @include margin(null null 68px);

    @include grid-media($md) {
      display: flex;
      flex-wrap: wrap;
    }

    &-img {
      @include margin(0 null 8px);
      background-size: cover !important;
      width: 100%;
    }

    &-title {
      @include margin(0 null 4px);
    }

    &-description {
      @include p6-body-copy($color: $dark-gray);
    }

    &-link {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      position: relative;
      width: 100%;
    }

    &-col {
      width: 100%;

      &:not(:last-child) {
        @include margin(null null 32px);
        height: fit-content;

        @include grid-media($md) {
          @include margin(null null 0);
          height: auto;
        }
      }

      &:first-child {
        @include grid-media($md) {
          @include margin(null 30px null null);
          max-width: calc(60% - 30px);

          .chapters__description {
            background: $white;
            position: relative;

            @include grid-media($md) {
              @include padding(8px null null);
              width: 100%;
            }
          }

          .chapters__grid-img {
            @include grid-media($md) {
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 0;
            }
          }
        }
      }

      .chapters__grid-img {
        @include padding(56.25% null null);

        @include grid-media($md) {
          @include padding(0 null null);
        }
      }

      &--2 {
        height: fit-content;

        @include grid-media($md) {
          max-width: 40%;
          width: 100%;
        }

        .chapters__grid-col {
          max-width: 100%;

          &:first-child {
            @include grid-media($md) {
              @include margin(null null 32px);
            }
          }
        }

        .chapters__grid-img {
          @include grid-media($md) {
            @include padding(40% null null);
            position: static !important;
          }
        }
      }
    }
  }

  &__list {
    @include margin(null null 48px);

    @include grid-media($md) {
      @include margin(null null 64px);
    }

    &-container {
      @include margin(null null 32px);

      @include grid-media($md) {
        display: grid;
        column-gap: 30px;
        grid-template-columns: 20% 1fr 1fr;
      }
    }

    &-col {
      @include margin(null null 32px);

      @include grid-media($md) {
        @include margin(null null 0);
      }
    }

    &-list {
      li {
        @include font(20px, 34px);

        &:not(:last-child) {
          @include margin(null null 4px);
        }

        &.latest {
          &:after {
            background-color: $green-blue;
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: 10px;
            position: relative;
            left: 5px;
            top: -8px;
            width: 10px;
          }
        }

        a {
          color: $thunder;
          font-weight: $font-normal;

          &:hover {
            color: $green-blue;
            text-decoration: underline;
            text-underline-position: under;
          }
        }
      }
    }

    &-title {
      @include margin(0 null 10px);
      @include padding(null null 19px);

      border-bottom: 1px solid $black;
    }

    &-wrapper {
      display: grid;
      column-gap: 28px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__indicator {
    @include p6-body-copy($color: $green-blue);
    display: inline-block;
    vertical-align: middle;

    &:before {
      @include margin(null 7px null null);
      background-color: $green-blue;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 10px;
      width: 10px;
    }
  }
}