.statement-photo {
  @include margin(null null 64px);

  @include grid-media($md) {
    @include margin(null null 128px);
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include grid-media($sm) {
      align-items: center;
      flex-direction: row;
      max-width: 1166px;
    }
  }

  &__text {
    @include margin(null null 32px);

    @include grid-media($md) {
      @include margin(null 30px 0 null);

      width: calc(100% - 410px);
    }

    p {
      @include p2-body-copy($color: $black);

      u {
        text-decoration: underline 5px solid $green-blue;
        text-underline-offset: 2px;

        @include grid-media($sm) {
          text-decoration: underline 10px solid $green-blue;
          text-underline-offset: 5px;
        }
      }
    }
  }

  &__image {
    @include margin(null auto);
    text-align: center;

    @include grid-media($md) {
      @include margin(null 0);
    }

    img {
      max-height: 600px;
      object-fit: cover;
    }
  }

  &--invert {
    .container {
      @include grid-media($md) {
        flex-direction: row-reverse;
      }
    }

    .statement-photo__text {
      @include grid-media($md) {
        @include margin(null 0 0 30px);
      }
    }
  }
}