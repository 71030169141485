.page-template-template-about {
  .cta-newsletter {
    @include margin(18px null null);

    @include grid-media($sm) {
      @include margin(48px null null);
    }
  }

  .topics-slider {
    @include padding(null null 64px);
  }
}