.hero-specialized {
  height: 286px;
  position: relative;

  @include grid-media($sm) {
    height: 618px;
    text-align: center;
  }

  * {
    color: $white;
  }

  &__image {
    background-size: cover !important;
    display: none;
    height: 100%;
    width: 100%;

    @include grid-media($sm) {
      display: block;
    }

    &--mobile {
      display: block;

      @include grid-media($sm) {
        display: none;
      }
    }
  }

  &__text {
    left: 18px;
    position: absolute;
    top: 30px;
    max-width: 250px;
    width: 100%;

    @include grid-media($sm) {
      bottom: 30%;
      left: 0;
      max-width: unset;
      top: auto;
    }
  }

  &__title {
    font-family: $SignifierRegular;
    font-weight: $font-normal;
  }

  &__subtitle {
    @include p3-body-copy($color: $white);
    @include margin(null null 8px);

    @include grid-media($sm) {
      @include margin(null null 17px);
    }
  }
}