.solutions {
    border-top: 2px solid #000;

    .component__content {
        margin: 0;
        padding: 75px 50px 69px;
        max-width: 100%;

        @media (max-width: 800px){
            padding: 41.94px 18px 49.5px;
        }
    }

    &__heading {
        color: #17AEA5;
        font-family: $GTAmericaMedium;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 0;

        @media (max-width: 800px) {
            font-size: 32px;
            line-height: normal;
            letter-spacing: 0.16px;
        }
    }

    &__description {
        font-family: $GTAmericaMedium;
        font-weight: 700;
        font-size: 30px;
        line-height: 46px;
        display: flex;
        align-items: left;
        padding-top: 35px;
        letter-spacing: 0;
        margin-bottom: 0 !important;

        @media (max-width: 800px) {
            font-size: 20px;
            line-height: normal;
            letter-spacing: 0.1px;
        }
    }

    @media (max-width: 800px){
        border-top: 1px solid #000;
    }
}
