.cessation {
    font-family: $GTAmericaMedium;

    .component__content {
        margin: 0;
        padding: 0 50px;
        max-width: 100%;

        @media (max-width: 800px) {
            padding: 0 18px;
        }
    }

    &__header {
        display: grid;
        grid-template-columns: 2fr 3fr;
        align-items: start;
        margin: 0;
        border-top: 1px solid #2f2f2f;
        grid-gap: 167px;
        padding-top: 40px;
        padding-bottom: 57px;

        &__right {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 34px;
            margin-top: 0;

            @media (max-width: 800px) {
                grid-template-columns: 1fr;
                grid-gap: 24px;
            }
        }

        &.mass-media {
            align-items: start;

            @media (max-width: 800px) {
                grid-template-columns: 1fr;
                padding-top: 23px;
            }
        }

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
            grid-gap: 16px;
            padding-bottom: 38px;
            padding-top: 35px;
        }

        &__content {
            &.mobile {
                @media (max-width: 800px) {
                    padding-left: 21px;
                }

                .cessation__subheading {
                    @media (max-width: 800px) {
                        padding-left: 21px;
                    }
                }

                .cessation__text {
                    &.answer {
                        @media (max-width: 800px) {
                            padding-left: 21px;
                        }
                    }
                }
            }
        }
    }

    &__heading {
        color: #17AEA5;
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0 !important;

        @media (max-width: 800px) {
            font-size: 20px;
            line-height: 26px;
            letter-spacing: 0.1px;
        }
    }

    &__description {
        display: grid;
        grid-template-columns: 40% 60%;
    }

    &__subheading {
        font-family: $GTAmericaMedium;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;

        @media (max-width: 800px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
        }
    }

    &__text,
    &__text-none {
        color: #17AEA5;
        font-family: $GTAmericaMedium;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin: 0 !important;
        position: relative;

        &.answer {
            font-size: 48px;
            line-height: 48px;

            @media (max-width: 800px) {
                font-size: 32px;
                line-height: 32px;
                letter-spacing: 0.16px;
            }
        }

        &.not-selected {
            font-family: $GTAmericaRegular;
            font-weight: 400;

            @media (max-width: 800px) {
                padding-left: 52px;
            }
        }

        &.selected {
            &::before {
                content: url('../dxdy/images/selection-check.svg');
                position: absolute;
                left: -25px;
                width: 16px;
                height: 13px;
                top: 5px;

                @media(max-width: 800px) {
                    left: 29px;
                    top: 0px;
                }
            }

            @media (max-width: 800px) {
                padding-left: 52px;
            }
        }

        &.none-selected {
            &::before {
                content: url('../dxdy/images/none-selection-check.svg');
                position: absolute;
                left: -25px;
                width: 16px;
                height: 13px;
                top: 5px;

                @media(max-width: 800px) {
                    left: 29px;
                    top: 0px;
                }
            }

            @media (max-width: 800px) {
                padding-left: 52px;
            }
        }

        @media (max-width: 800px) {
            font-size: 16px;
            line-height: normal;
            letter-spacing: 0.08px;
        }
    }

    &__text-none {
        color: $maroon;
    }

    .harms__learn-more {
        a {
            color: #17AEA5;
        }
    }
}
