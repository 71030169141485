.single-challenges,
.single-solutions {
  .addtoany_shortcode {
    @include margin(32px auto 16px);

    @include grid-media($md) {
      @include margin(32px auto);
    }

    .a2a_kit {
      @include grid-media($md) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      >a {
        @include grid-media($sm) {
          @include margin(null null 16px);
        }
      }
    }
  }
}