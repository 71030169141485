.smoking {
    .component__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 70px 33px;
        align-items: start;
        margin: 0;
        padding: 0 50px 50px;
        max-width: 100%;
        font-family: $GTAmericaMedium;

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
            grid-gap: 48px 33px;
            padding: 0 18px 50px;
        }
    }

    &__heading {
        color: #C05344;
        font-size: 24px;
        font-weight: 700;
        font-family: $GTAmericaMedium;
        line-height: normal;
        margin: 0;

        @media (max-width: 800px) {
            font-size: 20px;
            letter-spacing: 0.1px;
            margin-bottom: 8px;
        }
    }

    &__subheading {
        font-family: $GTAmericaRegular;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
        padding-top: 10px;

        @media (max-width: 800px) {
            line-height: 24px;
            margin-bottom: 16px;
        }
    }

    &__content {
        border-top: 1px solid #C05344;
        padding-top: 28px;

        @media(max-width:800px) {
            padding-top: 16px;
        }
    }

    &__content {
        .info__div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;

            @media(max-width:800px) {
                grid-template-columns: 100%;
                grid-gap: 0;
            }
        }

        &.learn-more {
            border-top: none;
            padding-top: 0;
            display: flex;
            align-self: center;

            @media(max-width:800px) {
                justify-content: center;
            }
        }
    }

    &__gender {
        font-size: 22px;
        font-weight: 700;
        font-family: $GTAmericaMedium;
        line-height: 34px;
        letter-spacing: 0.11px;
        margin: 0;

        @media(max-width:800px) {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.09px;
        }
    }

    &__percentage {
        color: #C05344;
        font-family: $GTAmericaMedium;
        font-size: 72px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: 0.36px;
        margin-top: 10px;
        margin-bottom: 36px !important;

        &.men-women {
            margin-top: 15px;
            font-size: 72px;
            line-height: 72px;
            letter-spacing: 0.36px;

            @media(max-width:800px) {
                font-size: 48px;
                line-height: 48px; 
            }
        }

        &.number {
            font-size: 38px;
            line-height: 72px;
            letter-spacing: 0.19px;

            @media(max-width:1300px) {
                font-size: 30px;
                line-height: 48px;
            }

            @media(max-width:800px) {
                font-size: 35px;
                line-height: 48px;
            }
        }

        @media(max-width:1300px) {
            font-size: 37px;
            line-height: 48px;
            margin-top: 0;
            margin-bottom: 16px !important;
        }

        @media(max-width:800px) {
            font-size: 48px;
            line-height: 48px;
            margin-top: 0;
            margin-bottom: 16px !important;
        }
    }

    &__description {
        max-width: 602px;
        font-family: $GTAmericaMedium;
        font-size: 22px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0 !important;

        @media(max-width:800px) {
            font-size: 20px;
            line-height: 24px;
            margin-top: 40px;
        }
    }

    &__learn-more__links {
        color: #2F2F2F;
        text-align: center;
        font-family: $GTAmericaRegular;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;

        a {
            color: #C05344;
            font-family: $GTAmericaMedium;
            font-weight: 700;
            font-size: 32px;

            @media(max-width:800px) {
                font-size: 24px;
            }
        }

        @media(max-width:800px) {
            font-size: 24px;
            line-height: 29px;
            margin-top: 48px;
            margin-bottom: 0 !important;
        }
    }
}