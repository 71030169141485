.statement {
  @include margin(24px null);

  @include grid-media($sm) {
    @include margin(32px null);
  }

  * {
    @include font(20px, 28px);

    color: $green-blue;

    @include grid-media($sm) {
      @include font(44px, 62px)
    }
  }

  &__wrapper {
    @include margin(null 0 null auto);
    @include padding(16px null);

    border-bottom: 1px solid $green-blue;
    border-top: 1px solid $green-blue;

    @include grid-media($md) {
      max-width: 90%;
    }
  }

  &__content {
    @include grid-media($md) {
      width: 55%;
    }
  }
}