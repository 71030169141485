.cta-link {
  @include margin(24px null);

  @include grid-media($sm) {
    @include margin(32px null);
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.4s ease-in-out;

    @include grid-media($sm) {
      align-items: center;
      background: $light-green;
      background: linear-gradient(90deg,
          rgba(242, 238, 231, 1) 0%,
          rgba(227, 241, 240, 1) 0%,
          rgba(227, 241, 240, 1) 92.5%,
          rgba(255, 255, 255, 1) 92.5%,
          rgba(255, 255, 255, 1) 100%);
    }
  }

  &__text {
    @include p3-body-copy($color: $black);
    @include padding(16px);

    background: $light-green;
    transition: all 0.4s ease-in-out;
    width: 80%;

    @include grid-media($sm) {
      @include padding(32px 57.5px);

      background: none;
      width: calc(100% - 105px);
    }

    span {
      color: $green-blue;
    }
  }

  &__arrow {
    align-items: center;
    display: flex;
    background: $green-blue;
    color: $white;
    justify-content: center;
    width: 20%;

    @include grid-media($sm) {
      height: 52px;
      max-width: 105px;
      width: 100%;
    }

    svg {
      height: 16px;
      width: 15px;

      @include grid-media($sm) {
        width: 100%;
      }
    }

    &--small {
      @include grid-media($sm) {
        display: none;
      }
    }

    &--large {
      display: none;

      @include grid-media($sm) {
        display: block;
      }
    }
  }

  &:hover {
    .cta-link__wrapper {
      background: $light-pink;
      background: linear-gradient(90deg,
          rgba(242, 238, 231, 1) 0%,
          rgba(251, 240, 238, 1) 0%,
          rgba(251, 240, 238, 1) 92.5%,
          rgba(255, 255, 255, 1) 92.5%,
          rgba(255, 255, 255, 1) 100%);
    }

    .cta-link__text span {
      color: $maroon;
    }

    .cta-link__arrow {
      background: $maroon;
    }
  }

  // Background modifiers
  &--beige {
    .cta-link__wrapper {
      @include grid-media($sm) {
        background: $beige;
        background: linear-gradient(90deg,
            rgba(242, 238, 231, 1) 0%,
            rgba(242, 238, 231, 1) 0%,
            rgba(242, 238, 231, 1) 92.5%,
            rgba(255, 255, 255, 1) 92.5%,
            rgba(255, 255, 255, 1) 100%);
        min-height: 170px;
      }
    }

    .cta-link__text {
      @include padding(50px 50px 50px 32px);
      background: $beige;

      @include grid-media($sm) {
        background: none;
      }
    }
  }
}