/**
  FONTS
  import fonts
**/

@font-face {
  font-family: 'GTAmericaBlack';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/GTAmericaBlack.woff') format('woff'),
    url('assets/fonts/GTAmericaBlack.svg') format('svg'),
    url('assets/fonts/GTAmericaBlack.woff2') format('woff2'),
    url('assets/fonts/GTAmericaBlack.ttf') format('ttf'),
    url('assets/fonts/GTAmericaBlack.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'GTAmericaBold';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/GTAmericaBold.woff') format('woff'),
    url('assets/fonts/GTAmericaBold.svg') format('svg'),
    url('assets/fonts/GTAmericaBold.woff2') format('woff2'),
    url('assets/fonts/GTAmericaBold.ttf') format('ttf'),
    url('assets/fonts/GTAmericaBold.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'GTAmericaCondensedBold';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/GTAmericaCondensedBold.woff') format('woff'),
    url('assets/fonts/GTAmericaCondensedBold.svg') format('svg'),
    url('assets/fonts/GTAmericaCondensedBold.woff2') format('woff2'),
    url('assets/fonts/GTAmericaCondensedBold.ttf') format('ttf'),
    url('assets/fonts/GTAmericaCondensedBold.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'GTAmericaMedium';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/GTAmericaMedium.woff') format('woff'),
    url('assets/fonts/GTAmericaMedium.svg') format('svg'),
    url('assets/fonts/GTAmericaMedium.woff2') format('woff2'),
    url('assets/fonts/GTAmericaMedium.ttf') format('ttf'),
    url('assets/fonts/GTAmericaMedium.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'GTAmericaRegular';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/GTAmericaRegular.woff') format('woff'),
    url('assets/fonts/GTAmericaRegular.svg') format('svg'),
    url('assets/fonts/GTAmericaRegular.woff2') format('woff2'),
    url('assets/fonts/GTAmericaRegular.ttf') format('ttf'),
    url('assets/fonts/GTAmericaRegular.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'SignifierBlack';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/SignifierBlack.woff') format('woff'),
    url('assets/fonts/SignifierBlack.svg') format('svg'),
    url('assets/fonts/SignifierBlack.woff2') format('woff2'),
    url('assets/fonts/SignifierBlack.ttf') format('ttf'),
    url('assets/fonts/SignifierBlack.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'SignifierBold';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/SignifierBold.woff') format('woff'),
    url('assets/fonts/SignifierBold.svg') format('svg'),
    url('assets/fonts/SignifierBold.woff2') format('woff2'),
    url('assets/fonts/SignifierBold.ttf') format('ttf'),
    url('assets/fonts/SignifierBold.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'SignifierExtralight';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/SignifierExtralight.woff') format('woff'),
    url('assets/fonts/SignifierExtralight.svg') format('svg'),
    url('assets/fonts/SignifierExtralight.woff2') format('woff2'),
    url('assets/fonts/SignifierExtralight.ttf') format('ttf'),
    url('assets/fonts/SignifierExtralight.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'SignifierLight';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/SignifierLight.woff') format('woff'),
    url('assets/fonts/SignifierLight.svg') format('svg'),
    url('assets/fonts/SignifierLight.woff2') format('woff2'),
    url('assets/fonts/SignifierLight.ttf') format('ttf'),
    url('assets/fonts/SignifierLight.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'SignifierMedium';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/SignifierMedium.woff') format('woff'),
    url('assets/fonts/SignifierMedium.svg') format('svg'),
    url('assets/fonts/SignifierMedium.woff2') format('woff2'),
    url('assets/fonts/SignifierMedium.ttf') format('ttf'),
    url('assets/fonts/SignifierMedium.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'SignifierRegular';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/SignifierRegular.woff') format('woff'),
    url('assets/fonts/SignifierRegular.svg') format('svg'),
    url('assets/fonts/SignifierRegular.woff2') format('woff2'),
    url('assets/fonts/SignifierRegular.ttf') format('ttf'),
    url('assets/fonts/SignifierRegular.eot') format('eot');
  font-display: swap;
}

@font-face {
  font-family: 'SignifierThin';
  font-style: normal;
  font-weight: normal;
  src:
    url('assets/fonts/SignifierThin.woff') format('woff'),
    url('assets/fonts/SignifierThin.svg') format('svg'),
    url('assets/fonts/SignifierThin.woff2') format('woff2'),
    url('assets/fonts/SignifierThin.ttf') format('ttf'),
    url('assets/fonts/SignifierThin.eot') format('eot');
  font-display: swap;
}