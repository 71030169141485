.single-image {
  @include margin(32px null);

  &__title {
    @include margin(null null 28px);

    @include grid-media($sm) {
      @include margin(null null 48px);
      @include font(32px, 32px);
    }
  }

  &__img {
    object-fit: cover;
  }

  &__caption {
    @include margin(8px null null);
    @include caption;
    color: $gray-light  !important;

    @include grid-media($sm) {
      @include margin(16px null null);
    }
  }
}