.stats {
  text-align: center;
  &:not(:last-child) {
    @include margin(null null 48px);
  }

  &__country {
    @include margin(0 null 14px);
    @include p4-body-copy;
    font-family: $GTAmericaBold;
    text-align: center;

    @include grid-media($md) {
      @include margin(0 null 16px);
    }
  }

  &__arrow {
    &--down {
      transform: rotate(180deg);
    }
  }

  dt {
    @include font(32px, 32px);
    font-family: $GTAmericaBold;
    font-weight: $font-bold;

    @include grid-media($md) {
      @include font(90px, 95px);
    }

    span {
      color: $green-blue;
    }

    svg {
      color: $green-blue;
      height: 22px;
      width: 13px;

      @include grid-media($md) {
        height: 37px;
        width: 23px;
      }
    }
  }

  dd {
    @include p4-body-copy;
    @include margin(null null null 4px);
  }
}
