.tax-policies {
    font-family: $GTAmericaRegular;

    .component__content {
        margin: 0;
        padding: 0 50px;
        max-width: 100%;

        @media (max-width:800px) {
            padding: 0 18px;
        }
    }

    &__inner {
        border-top: 1px solid #2f2f2f;
        padding: 44px 0;

        @media (max-width:800px) {
            padding: 44px 0 46px;
        }
    }

    &__heading {
        color: #17AEA5;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 24px;
        font-family: $GTAmericaBold;

        @media (max-width:800px) {
            font-size: 24px;
            line-height: normal;
            margin-bottom: 32px;
        }
    }

    &__description {
        color: #000;
        font-family: $GTAmericaMedium;
        font-size: 30px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 36px;

        @media (max-width:800px) {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.09px;
            margin-bottom: 66px;
        }
    }

    &__scores {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: center;
        gap: 20px 1%;

        @media (max-width:800px) {
            display: block;
        }
    }

    &__score {
        width: 19%;
        padding: 20px;

        &:first-child {
            background: rgba(23, 174, 165, 0.12);

            @media (max-width:800px) {
                padding: 32px 18px;
                margin-bottom: 48px;
            }

            .tax-policies__score-heading {
                font-size: 18px;
            }

            .tax-policies__score-number {
                font-size: 72px;
                margin-bottom: 30px;

                @media (max-width:800px) {
                    font-size: 90px;
                    line-height: 95px;
                }

                &.insufficent-overall-score {
                    font-size: 48px;
                    line-height: 48px;

                    @media (max-width:1440px) {
                        font-size: 37px;
                        line-height: 40px;
                    }

                    @media (max-width:800px) {
                        font-size: 48px;
                        line-height: 48px;
                    }
                }
            }
        }

        &-heading {
            font-family: $GTAmericaMedium;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 24px;
            height: 48px;

            @media (max-width:800px) {
                font-size: 18px;
                height: auto;
            }
        }

        &-number {
            color: #17AEA5;
            font-size: 48px;
            font-weight: 700;
            line-height: 48px;
            margin-bottom: 8px;
            font-family: $GTAmericaMedium;

            &.insufficent {
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                max-width: 155px;

                @media (max-width:1200px) {
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 44px;
                }

                @media (max-width:800px) {
                    margin-bottom: 44px;
                    font-size: 48px;
                    line-height: 48px;
                }
            }

            @media (max-width:800px) {
                font-size: 64px;
                line-height: 95px;
            }
        }

        &-description {
            color: #2f2f2f;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0 !important;
        }

        @media (max-width:800px) {
            width: 100%;
            margin-bottom: 30px;
            padding: 0 18px;
        }
    }

    .harms__learn-more {
        margin-bottom: 0 !important;
        padding-top: 44px;

        a {
            color: #17AEA5;
            font-size: 32px !important;

            @media (max-width:800px) {
                font-size: 24px !important;
            }
        }

        @media (max-width:800px) {
            padding-top: 8px;
        }
    }
}