// Evergreen page featured below hero
.featured-wrapper {
  @include grid-media($sm) {
    @include margin(-128px null 52px);
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include grid-media($md) {
      flex-direction: row;
    }
  }

  .featured-text {
    @include margin(-16px -18px null);
    @include padding(24px 16px 32px);

    @include grid-media($sm) {
      @include margin(null -50px);
      @include padding(54px 78px 54px 63px);
    }

    @include grid-media($md) {
      @include margin(null 0);

      width: 75%;
    }
  }

  .share,
  .addtoany_shortcode {
    @include grid-media($md) {
      @include margin(198px auto 0 13.5%);

      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  // For specialized
  &--specialized {
    @include margin(-40px null 32px);
    overflow: unset;

    @include grid-media($sm) {
      @include margin(-128px null null);
    }

    .container {
      @include grid-media($sm) {
        max-width: 1166px;
      }
    }

    .featured-text {
      @include margin(null 0);
      @include padding(27px 30px 40px);
      background: $white;
      text-align: center;

      @include grid-media($sm) {
        @include padding(55px 116px 55px 104px);
      }

      @include grid-media($md) {
        width: 100%;
      }

      &__title {
        @include margin(null null 25px);
        @include font(14px, 114%, 0.005em);

        color: $black;
        font-weight: $font-bold;
        text-transform: uppercase;
        letter-spacing: 0.3em;

        @include grid-media($sm) {
          @include margin(null null 28px);
        }

        @include grid-media($md) {
          @include font(28px, 125%, 0.005em);
        }
      }

      &__description {
        @include font(14px, 20px, 0.005em);
        color: $thunder;

        @include grid-media($sm) {
          @include font(30px, 46px, 0);
        }
      }
    }
  }

  // Common
  &--normal {
    @include margin(0 null 32px);

    @include grid-media($sm) {
      @include margin(null null 80px);
    }

    .container {
      @include margin(null auto null 0);
      max-width: 1166px;
    }

    .featured-text {
      @include padding(32px 0);
      @include p2-body-copy($color: $thunder);
      background: none;
      border-bottom: 1px solid $thunder;
      font-weight: $font-normal;
      width: 100%;
    }
  }
}

.featured-text {
  background: $green-blue;

  * {
    color: $white;
    font-family: $GTAmericaBold;
    font-weight: $font-normal;

    @include font(14px, 24px, 0.005em);

    @include grid-media($sm) {
      @include font(26px, 46px, 0);
    }
  }
}