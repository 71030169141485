
.button {
  @include font(14px, 143%, 0.005em);
  @include padding(16px);
  @include margin(38px);
  
  background: $green-blue;
  color: $white;
  border: 2px solid transparent;
  display: inline-block;
  font-family: $GTAmericaRegular;
  transition: all $transition-single $cubic-transition;

  @include grid-media($lg) {
    @include font(20px, 170%, 0);
    @include padding(28px 31px);
  }

  &:hover,
  &:focus {
    background: transparent;
    border: 2px solid $green-blue;
    color: $green-blue;
  }

  &--secondary {
    color: $white;
    background: $maroon;
    border: 2px solid transparent;

    &:hover,
    &:focus {
      background: $white;
      border: 2px solid $maroon;
      color: $maroon;
    }
  }
}