.embed {
  @include margin(null null 16px);

  @include grid-media($sm) {
    @include margin(null null 48px)
  }

  iframe[aria-label="Column Chart"] {
    @include grid-media($md) {
      height: 450px !important;
    }
  }
}