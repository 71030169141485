/**
* Utility classes
*/

/**
* Wordpress image alignment
*/
.alignright {
  float: none;

  @media screen and (min-width: '#{$sm-breakpoint}') {
    float: right;
  }
}

.alignleft {
  float: none;

  @media screen and (min-width: '#{$sm-breakpoint}') {
    float: left;
  }
}

.aligncenter {
  margin: 0 auto;
  max-width: 100%;
}

.show-desktop {
  display: none;

  @include grid-media($md) {
    display: block;
  }
}

.show-mobile {
  display: block;

  @include grid-media($md) {
    display: none;
  }
}