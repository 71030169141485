.accordion {
  @include margin(-30px null 64px);
  background: $gray-lighter;

  @include grid-media($md) {
    @include margin(-240px null 174px);
    @include padding(null 55px);
  }

  &__title {
    @include h8-xl($color: $thunder);
    @include padding(22px 135px 22px 0);

    cursor: pointer;
    border-top: 1px solid $light-brown;
    font-weight: $font-normal;
    position: relative;
    transition: all $transition-single ease;

    &:after {
      content: none;
      display: none;

      @include grid-media($md) {
        background-size: contain;
        background: url("src/icons/icon-close.svg") center no-repeat;
        bottom: 0;
        content: "";
        display: block;
        height: 65px;
        opacity: 0;
        position: absolute;
        right: 35px;
        top: 40px;
        transition: all $transition-single ease;
        visibility: hidden;
        width: 65px;
      }
    }

    &:hover {
      background: $light-blue;
    }

    &-main {
      @include margin(32px null);
      @include padding(null 25px);
      letter-spacing: 0.3em;
      text-align: center;
      text-transform: uppercase;

      @include grid-media($md) {
        @include margin(60px null);
        @include padding(null 0);
      }
    }
  }

  &__item {
    &:last-child {
      .accordion__title {
        border-bottom: 1px solid $light-brown;
      }

      &.open-accordion {
        .accordion__content {
          border: none;
        }
      }
    }

    p {
      @include p5-body-copy($color: $black);

      @include margin(null null 32px);
    }

    &.open-accordion {
      .accordion__title {
        color: $green-blue;

        &:after {
          opacity: 1;
          visibility: visible;
        }

        &:hover {
          background: none;
        }
      }

      .accordion__content {
        border-top: 1px solid $light-brown;
      }
    }
  }

  &__content {
    @include padding(32px null null);
    display: none;

    @include grid-media($md) {
      @include padding(50px null null);
    }

    &-wrapper {
      @include grid-media($md) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
    }

    &-title {
      @include margin(null null 32px);
      color: $black;

      @include grid-media($md) {
        @include margin(null 22px 0 null);
        max-width: 326px;
      }
    }

    &-detail {
      @include grid-media($md) {
        @include padding(null 50px);
        width: calc(100% - 348px);
      }

      p {
        font-family: $GTAmericaRegular;
        font-weight: $font-normal;
      }

      h6 {
        color: $black;
        @include margin(32px null 0);
      }
    }
  }

  /* Small Accordion */
  &--small {
    @include margin(0 null null);
    background: none;

    .accordion__title {
      &:after {
        height: 28px;
        top: 25px;
        right: 0;
        width: 28px;
      }
    }

    .accordion__content {
      @include padding(40px null null);

      &-wrapper {
        display: unset;
      }

      &-detail {
        @include padding(0);
        width: 100%;
      }

      h6 {
        @include margin(null null 6px);

        &:first-of-type {
          @include margin(0 null null);
        }
      }
    }

    .accordion__title {
      @extend h6;

      &:after {
        background: url("assets/img/accordion-arrow.svg") center center
          no-repeat;
        opacity: 1;
        visibility: visible;
      }
    }

    .open-accordion {
      .accordion__title {
        &:after {
          background: url("src/icons/icon-close.svg") center no-repeat;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
